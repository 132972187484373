import { Component, Input } from '@angular/core';

@Component({
    selector: 'svg-button',
    templateUrl: './SvgButtonComponent.html',
    styleUrls: ['./SvgButtonComponent.scss']
})
export class SvgButtonComponent
{
    @Input() id: string;
    // @Input() icon: string;
    // @Input() tag: string;

    constructor() {}
}
