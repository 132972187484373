import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import firebase from 'firebase/app';

import { AngularFireFunctions } from '@angular/fire/functions';
import { BasicResponse, UserProfile } from 'functions/src/Data/DataTypes';

@Injectable({ providedIn: 'root' })
export class AuthMgr implements CanActivate
{
    uid = "";
    whiteListed = false;
    userProfile: UserProfile;
    loggedIn = false;

    started_dt = new Date().valueOf();

    url_history: string[] = [];

    constructor( private afAuth: AngularFireAuth,
            private func: AngularFireFunctions,
            private router: Router )
    {
        this.afAuth.authState.subscribe( async user => {
            if( user == null ) { return; }

            console.log( "Auth State Triggered!" );
            console.log( user );
            
            this.uid = user.uid;
            this.userProfile = await this.getProfile();
        })
    }

    // Route Guard
    async canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {

        console.log( `Can Activate: `);
        if( this.url_history.length === 0 || this.url_history[ this.url_history.length - 1] !== this.router.url ) {
            console.log( `Adding history ${this.router.url}`);
            this.url_history.push( this.router.url );
        }

        if( ! await this.isLoggedIn() ) { 
            console.log( `Not logged in`);
            return this.router.parseUrl('/login'); 
        } 
        if( ! await this.isWhiteListed() ) {
            console.log( `Not whitelisted in`);
            return this.router.parseUrl('/waitlist'); 
        }
        if( ! await this.hasProfile() ) { 
            console.log( `No profile`);
            return this.router.parseUrl('/welcome'); 
        }

        console.log( `Activate Router Success`);
        return true;
    }

    async navigateOnAuthChange() {
        const tree = await this.canActivate( null, null );
            
        if(typeof tree === 'object') {
            this.router.navigateByUrl( tree );
            return;
        }

        // Go to our history
        if( this.url_history.length > 0 ) {
            if( this.url_history[0].startsWith( "/user" )) {
                this.router.navigate( [ this.url_history[0] ]);
                return;
            }
        }

        // Who knows - let's go home
        this.router.navigate( [ "/user", this.userProfile.id ]);
    }

    private async waitForIt() {

        const now = new Date().valueOf();
        const myPromise = new Promise( (resolve) => {
            this.afAuth.onAuthStateChanged( user => {
                console.log( `auth state changed!`);
                console.log( user );
                const otherNow = new Date().valueOf();
                console.log( `DEEEEELAY was ${otherNow - now} ms`)
                resolve(true);
            });
        });
        await myPromise;
        return;
    }

    async isLoggedIn() {

        const now = new Date().valueOf();

        let user = await this.afAuth.currentUser;
        this.loggedIn = !!user;
        console.log( `IsLoggedIn: ${this.loggedIn}`);
        
        // 1.5 seconds
        if( ! this.loggedIn && now - this.started_dt < 3000 ) {
            console.log("Calling Wait for it )")
            await this.waitForIt();
        }

        user = await this.afAuth.currentUser;
        this.loggedIn = !!user;
        console.log( `IsLoggedIn: ${this.loggedIn}`);
        return this.loggedIn;
    }

    getAuthorId(): string
    {
        if( this.userProfile == null ) { throw Error( "No user profile yet!" ); }
        return this.userProfile.id;
    }

    async isWhiteListed()
    {
        if( ! this.whiteListed ) {
            // try to pull it with an HTTPS call
            const user = await this.afAuth.currentUser;
            const email = user.email;
            console.log("Matching to email: "+email );
            this.whiteListed = await this.httpsOnCall( OnCallFunctions.IS_WHITE_LISTED, email );
        }
        console.log( `White Listed: ${this.whiteListed}`);
        return this.whiteListed;
    }

    async hasProfile() {
        const profile = await this.getProfile();
        console.log( `Has Profile: ${profile}`);
        return profile != null;
    }

    async getProfile(): Promise<UserProfile>
    {
        if( this.uid === '' ) {
            console.log("Not logged in yet..." );
            return null;
        }

        if( this.userProfile == null ) {
            this.userProfile = await this.httpsOnCall( OnCallFunctions.LOAD_USER_PROFILE, null );
        }
        return this.userProfile;
    }

    async setProfile( profile: UserProfile )
    {
        if( this.userProfile == null ) {

            const basicResponse: BasicResponse = await this.httpsOnCall( OnCallFunctions.CREATE_USER_PROFILE, profile );
            if( basicResponse.success ) {
                this.userProfile = profile;
            }
            else {
                console.error( basicResponse.reason );
            }
        }
        else {
            this.userProfile = await this.httpsOnCall( OnCallFunctions.UPDATE_USER_PROFILE, profile );
            this.userProfile = profile;
        }
        
        return this.userProfile;
    }

    async firebaseLogin(): Promise<string>
    {
        const provider = new firebase.auth.GoogleAuthProvider();

        // Verify the user is good
        const credential = await this.afAuth.signInWithPopup( provider );
        this.uid = credential.user.uid;
        console.log( `Login: ${credential.user}`);
        return this.uid;
    }

    async signOut(): Promise<void>
    {
        console.log( `Logout`);
        this.userProfile = null;
        this.uid = null;
        await this.afAuth.signOut();
        this.router.navigate(['/login']);
    }

    isAdmin(): boolean
    {
        return true;
    }    

    async isUserIdAvailable( user_profile_id: string ): Promise<BasicResponse>
    {
        try
        {
            const basicResponse: BasicResponse = await this.httpsOnCall( OnCallFunctions.USER_ID_AVAILABLE, user_profile_id );
            console.log( basicResponse );
            return basicResponse;
        }
        catch( Exception ) {
            const basicResponse: BasicResponse = { success: false, reason: `Failed: ${Exception}` };
            console.log( basicResponse );
            return basicResponse;
        }
    }

    // Returns Object, not string
    async httpsOnCall( function_name: OnCallFunctions, bodyObj: any ): Promise<any>
    {
        console.log( `Calling https ${function_name}`);
        console.log( bodyObj );
        const httpFunction = this.func.httpsCallable( function_name );
        return await (httpFunction( bodyObj ).toPromise<any>());
    }
}

export enum OnCallFunctions
{
    USER_ID_AVAILABLE = "userIdAvailable",
    IS_WHITE_LISTED = "isWhiteListed",

    CREATE_USER_PROFILE = "createUserProfile",
    LOAD_USER_PROFILE = "loadUserProfile",
    UPDATE_USER_PROFILE = "updateUserProfile",

    CREATE_POST = "createPost",
    UPDATE_POST = "updatePost",

    ADD_COMMENT = "addComment",
    UPDATE_COMMENT = "updateComment",

    LOAD_PAGE = "loadPage"
}
