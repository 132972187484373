
import { Component, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { QuillEditorComponent } from "ngx-quill";

import ImageResize from 'quill-image-resize-module';
import {ImageDrop} from 'quill-image-drop-module';

import QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
Quill.register( 'modules/imageResize', ImageResize );
Quill.register( 'modules/imageDrop', ImageDrop );


var FontAttributor = Quill.import('attributors/class/font');
FontAttributor.whitelist = [
  'sofia', 'slabo', 'roboto', 'inconsolata', 'ubuntu', 'amatic'
];
Quill.register(FontAttributor, true);


@Component({
	selector: "text-editor",
	templateUrl: "./TextEditorComponent.html",
	styleUrls: ["./TextEditorComponent.scss"]
})
export class TextEditorComponent
{
    @ViewChild(QuillEditorComponent) editor: QuillEditorComponent;

    showAdvanced = false;
    @Input() set advanced( value: boolean ) {
        this.showAdvanced = value;
    }

    @Input() text: string;
    @Output() textChange = new EventEmitter<string>();

    quillBasicConfig = {
        toolbar: {
            container: [ 
                ['bold', 'italic'],
            ],
        },
        keyboard: { bindings: {
            enter: { key:13, handler: (range, context) => true }
        } },
    };

    quillAdvancedConfig = {

        
        toolbar: '#toolbar',
        imageResize: {
            handleStyles: { backgroundColor: 'black', border: 'none', color: 'white' }
        },
        imageDrop: true,
        keyboard: {
            bindings: {
                enter: { key:13, handler: (range, context) => true }
            }
        },
    };

    constructor() 
    {   
    }

    onContentChanged = (event) => { this.textChange.emit( event.html ); }
}
