
import { Component, Input } from '@angular/core';
import { UserProfile } from 'functions/src/Data/DataTypes';
import { StorageMgr } from 'src/app/provider/StorageMgr';

@Component({
	selector: "profile-card",
	templateUrl: './ProfileCardComponent.html',
    styleUrls: ['./ProfileCardComponent.scss'],
})
export class ProfileCardComponent
{
    profileSrc = '';

    internalProfile: UserProfile;
    @Input() set profile( value: UserProfile )
    {
        this.setupProfile( value );
    }

    constructor( private storageMgr: StorageMgr )
    {
    }

    private async setupProfile( profile: UserProfile ) 
    {
        this.internalProfile = profile;

        // Load the profile pic if possible
        if( this.internalProfile != null && this.internalProfile.profile_storage_id != null &&
                this.internalProfile.profile_storage_id !== '' ) 
        {
            const storageItem = await this.storageMgr.load( this.internalProfile.profile_storage_id );
            if( storageItem != null ) {
                this.profileSrc = storageItem.content;
                return;
            }
        }
        this.profileSrc = '';
    }
}

