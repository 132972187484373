
<div class="{{ fullPageSet.has( router.url ) ? 'full-page' : 'layout-main' }}">

    <ng-container *ngIf="! fullPageSet.has( router.url ) ">

        <!-- <ActionBar ></ActionBar> -->

        <nav class="navbar">

            <ul class="navbar-nav">

                <li class="logo">
                    <a href="javascript:void(0)" class="nav-link">
                        <span class="link-text logo-text">
                            <span class="w">Moon-Letters</span>
                        </span>
                        <svg-icon id="right"></svg-icon>
                    </a>
                </li>
                
                <li class="nav-item" routerLinkActive="active" [routerLink]="['login']" *ngIf="!auth.isLoggedIn">
                    <a href="javascript:void(0)" class="nav-link">
                        <svg-icon id="login"></svg-icon>
                        <span class="link-text">Login</span>
                    </a>
                </li>

                <ng-container *ngIf="auth.isLoggedIn">

                    <li class="nav-item" routerLinkActive="active" [routerLink]="['qualify']" >
                        <a href="javascript:void(0)" class="nav-link">
                            <svg-icon id="qualify"></svg-icon> <span class="link-text">Qualify</span>
                        </a>
                    </li>

                    <!-- <li class="nav-item nav-dropdown" routerLinkActive="active open" [routerLink]="['main']" >
                        <a href="javascript:void(0)" class="nav-link">
                            <svg-icon id="users"></svg-icon>
                            <span class="link-text">Account</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active" [routerLink]="['Text']" >
                        <a href="javascript:void(0)" class="nav-link">
                            <svg-icon id="text"></svg-icon> <span class="link-text">Text</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active" [routerLink]="['noc']" >
                        <a href="javascript:void(0)" class="nav-link">
                            <svg-icon id="noc"></svg-icon> <span class="link-text">NOC</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active" [routerLink]="['towers']" >
                        <a href="javascript:void(0)" class="nav-link">
                            <svg-icon id="towers"></svg-icon> <span class="link-text">Towers</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active" [routerLink]="['expansion']" >
                        <a href="javascript:void(0)" class="nav-link">
                            <svg-icon id="expanse"></svg-icon> <span class="link-text">Expanse</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active" [routerLink]="['scheduler']" >
                        <a href="javascript:void(0)" class="nav-link">
                            <svg-icon id="scheduler"></svg-icon> <span class="link-text">Scheduler</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active" [routerLink]="['Agents']" >
                        <a href="javascript:void(0)" class="nav-link">
                            <svg-icon id="agents"></svg-icon> <span class="link-text">Agents</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active" [routerLink]="['goods']" >
                        <a href="javascript:void(0)" class="nav-link">
                            <svg-icon id="settings"></svg-icon> <span class="link-text">Settings</span>
                        </a>
                    </li>

                    <li class="nav-item nav-dropdown" routerLinkActive="active open" [routerLink]="['employees']">
                        <a href="javascript:void(0)" class="nav-link">
                            <svg-icon id="employees"></svg-icon>
                            <span class="link-text">Employees</span>
                        </a>
                    </li> -->

                    <li class="nav-item" routerLinkActive="active" [routerLink]="['market']" >
                        <a href="javascript:void(0)" class="nav-link">
                            <svg-icon id="market"></svg-icon> <span class="link-text">Market</span>
                        </a>
                    </li>



                        <li class="nav-item" id="themeButton">
                    
                            <a href="javascript:void(0)" class="nav-link">
            
                                <svg-icon (click)="setTheme( 'droplet' )" class="theme-icon" id="starwars"></svg-icon>
                                <svg-icon (click)="setTheme( 'night' )" class="theme-icon" id="droplet" ></svg-icon>
                                <svg-icon (click)="setTheme( 'sunburst' )" class="theme-icon" id="night" ></svg-icon>
                                <svg-icon (click)="setTheme( 'vampire' )" class="theme-icon" id="sunburst" ></svg-icon>
                                <svg-icon (click)="setTheme( 'coffee' )" class="theme-icon" id="vampire" ></svg-icon>
                                <svg-icon (click)="setTheme( 'trees' )" class="theme-icon" id="coffee" ></svg-icon>
                                <svg-icon (click)="setTheme( 'starwars' )" class="theme-icon" id="trees" ></svg-icon>
                                <span class="link-text">{{ wizard.capitalizeIt(wizard.themeNow) }}</span> 
                            </a>
                        </li>

                </ng-container>

            </ul>
        </nav>

    </ng-container>

    <div class="main-area">
        <ng-content></ng-content>
    </div>

</div>
