
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'ActionItem',
    templateUrl: './ActionItemComponent.html',
    styleUrls: ['./ActionItemComponent.scss']
})
export class ActionItemComponent implements OnInit 
{
    @Input() icon: string;
    
    @Input() svgIcon: string;
     

    constructor()
    {
    }

    ngOnInit()
    {
    }

}
