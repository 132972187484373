// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    firebaseConfig: {
        apiKey: 'AIzaSyAAGrZqK2FQmgA_imqNRz1ZNByqXcGf3mg',
        authDomain: 'moon-letters.firebaseapp.com',
        projectId: 'moon-letters',
        storageBucket: 'moon-letters.appspot.com',
        messagingSenderId: '463454936468',
        appId: '1:463454936468:web:4847c12d1bbda6b1ed0381',
        measurementId: 'G-XWT7NQH2VW'
    }

    // apiKey: string;
    // authDomain: string;
    // projectId: string;
    // storageBucket: string;
    // messagingSenderId: string;
    // appId: string;
    // measurementId: string; };
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

