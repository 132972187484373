
import { AfterViewChecked, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthMgr } from '../provider/AuthMgr';
import { ThemeWizard } from './ThemeWizard';

@Component({
    selector: 'app-layout',
    templateUrl: './LayoutComponent.html',
    styleUrls: ['./LayoutComponent.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, AfterViewChecked
{
    accountKeyValueSubject: BehaviorSubject<{key: string, value: string}[]>;
    invoicesSubject: BehaviorSubject<string[]>;
    employeesSubject: BehaviorSubject<string[]>;
    wizard: ThemeWizard = new ThemeWizard();

    fullPageSet: Set<string> = new Set( ['/login', '/welcome', '/waitlist', '/404' ] );

    count = 0;

    constructor( public auth: AuthMgr, public router: Router,
                 private afAuth: AngularFireAuth  )
    {
        const theme = this.wizard.init();
        this.wizard.setTheme( theme.name );
    }

    setTheme( name: string )
    {
        this.wizard.setTheme( name );
    }

    ngOnInit() {
    }

    ngAfterViewChecked()
    {
        this.count++;
    }

    getUserName(): string
    {
        return '';
        // return this.auth.wispUserBSubject.getValue().displayName;
    }

    getUserImageURL(): string
    {
        return '';
        // return this.auth.wispUserBSubject.getValue().photoURL;
    }

    getStatus(): string
    {
        return '';
    }

    getSelectedAcct(): string
    {
        return '';
	}
	
	getSelectedInvoice(): string
	{
        return '';
    }

    getSelectedEmployee(): string
	{
        // return this.stateMgr.lastLoadedEmployee;
        return '';
	}
}
