
<div class="post-main">

    <div class="post-profile" (click)="goAuthor()"></div>

    <div class="post-author" (click)="goAuthor()">
        {{ internalPost?.author_name }}
    </div>
    <div class="post-author-id" (click)="goAuthor()">
        @{{ internalPost?.author_id }}
    </div>
    <div class="post-time">
       {{ timeStr }}
    </div>

    <div class="post-body" (click)="goBody()">
        <text-display [text]="internalPost?.short_txt"></text-display>
    </div>
 
    <div class="post-footer">
        <button mat-icon-button matTooltip="" class="post-comment">
            <i class=" mdi mdi-comment"></i>
        </button>
        <button mat-icon-button matTooltip="" class="post-retweet">
            <i class=" mdi mdi-postage-stamp"></i>
        </button>
        <button mat-icon-button matTooltip="" class="post-likes">
            <i class="mdi mdi-heart"></i>
        </button>
    </div>

</div>