
import { Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { UserProfileDialogInfo } from './ProfileMgr';
import { AuthMgr } from 'src/app/provider/AuthMgr';
import { BasicResponse } from 'functions/src/Data/DataTypes';

@Component({
    templateUrl: 'ProfileDialogComponent.html',
    styleUrls: ['ProfileDialogComponent.scss']
})
export class ProfileDialogComponent
{
    userProfileData: UserProfileDialogInfo;

    namesVerified: Set<string> = new Set();

    // State
    isNewProfile = false;

    lastTyped_dt = 0;
    isChecking = false;
    isValidId = true;
    isValid = true;
    nameStatus = "";

    // If they update the images, it goes here
    bgImageStr = "";
    profileImageStr = "";

    constructor( public dialogRef: MatDialogRef<ProfileDialogComponent>,
            private ngZone: NgZone,
            private auth: AuthMgr,
            @Inject( MAT_DIALOG_DATA ) public data: UserProfileDialogInfo )
    {
        this.userProfileData = data;
        this.isNewProfile = this.userProfileData.userProfile.id == null || 
                this.userProfileData.userProfile.id === '';

        // Don't let them exit without picking a valid user id
        if( this.isNewProfile ) { this.isValid = false; this.isValidId = false; }
    }

    addBackgroundImage() { console.log("addBackgroundImage()"); }
    addPic() { console.log( "adding pic..." ); }
    adjustPic() { console.log( "adjusting pic..." ); }

    idChanged() {

        this.lastTyped_dt = new Date().valueOf();
        const idToTest = this.userProfileData.userProfile.id;

        if( this.namesVerified.has( idToTest )) {
            this.isChecking = false;
            this.isValidId = true;
            this.isValid = true; // <- kill it?
            this.nameStatus = "";
            return;
        }
        this.isValidId = false;
        this.isValid = false; // <- kill it?
        this.nameStatus = "";

        // Spin off a timer to check in 500 ms
        const checkInABit = new Promise(r => setTimeout(r, 500));
        checkInABit.then( async () => {

            const now = new Date().valueOf();
            this.ngZone.run( () => this.isChecking = false );

            // If we've not waited since we last typed... we're done
            if( now - this.lastTyped_dt >= 500 ) {

                // Verify the name
                const result: BasicResponse = await this.auth.isUserIdAvailable( idToTest );

                this.ngZone.run( () => {
                    if( result.success ) {
                        this.namesVerified.add( idToTest );
                        this.isChecking = false;
                        this.isValidId = true;
                        this.isValid = true; // <- kill it?
                        this.nameStatus = "";
                        return;
                    }
                    this.isValidId = false;
                    this.isValid = false;
                    this.nameStatus = result.reason;
                    this.isChecking = false;
                });
            }
        });
    }

    updateBackground( event ) {
        const base64Img: string = event;
        this.bgImageStr = base64Img;
        this.userProfileData.bgImage = base64Img;
    }

    updateProfile( event ) {
        const base64Img: string = event;
        this.profileImageStr = base64Img;
        this.userProfileData.profileImage = base64Img;
    }
}
