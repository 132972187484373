
import { Component, Input, ViewChild } from "@angular/core";
import { QuillEditorComponent } from "ngx-quill";

@Component({
	selector: "text-display",
	templateUrl: "./TextDisplayComponent.html",
	styleUrls: ["./TextDisplayComponent.scss"]
})
export class TextDisplayComponent
{
    @ViewChild(QuillEditorComponent) editor: QuillEditorComponent;

    htmlText = "<p>Initial Note Content</p>";
    
    @Input() set text( value: string ) {
        this.htmlText = value;
    }

    quillBasicConfig = {
        toolbar: {
            container: [ 
                ['bold', 'italic'],
            ],
        },
        keyboard: { bindings: {
                enter: { key:13, handler: (range, context) => true }
        } },
    };

    constructor() 
    {   
    }
} 
