<div class="text-input">

    <div *ngIf="showAdvanced" id="toolbar" className="toolbar ql-toolbar ql-bubble">
        <span class="ql-formats">
            <select class="ql-font">
                <option selected>Sofia</option>
                <option value="slabo">Slabo</option>
                <option value="roboto" >Roboto</option>    
                <option value="inconsolata">Inconsolata</option>    
                <option value="ubuntu">Ubuntu</option>    
                <option value="amatic">Amatic SC</option>    
            </select>
            <select class="ql-size"></select>
        </span> 
        <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
        </span>
        <span class="ql-formats">
            <button class="ql-header" value="1"></button>
            <button class="ql-header" value="2"></button>
            <button class="ql-header" value="3"></button>
            <button class="ql-blockquote"></button>
            <button class="ql-code-block"></button>
        </span>
        <span class="ql-formats">
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
        </span>
        <span class="ql-formats">
            <button class="ql-link"></button>
            <button class="ql-image"></button>
            <button class="ql-clean"></button>
        </span>

    </div>

    <quill-editor class="text-body" id="editor" [(ngModel)]="text"
        [modules]="showAdvanced ? quillAdvancedConfig : quillBasicConfig" (onContentChanged)="onContentChanged($event)"
        theme="bubble" bounds="#editor" placeholder='The dazzling thought creation ... '>
    </quill-editor>


</div>