
    
<div class="main-dialog">
    
    <div class="top">
        <dnd-image class="post-image" (imgChange)="updateImage($event)">
        </dnd-image>
    </div>

    <div class="mid">
            
        <div class="post-title">
            <mat-form-field floatLabel="never" appearance="standard" class="short-title">
                <input matInput type="text" placeholder="Ye Title" autocomplete="off" 
                        [(ngModel)]="internalPost.post.title">
            </mat-form-field>
        </div>

        <div class="post-body">
            <text-editor [(text)]="internalPost.long_txt" ></text-editor>
        </div>

    </div>

    <div class="bot">
        <div class="ok-footer theRight">
            <button mat-icon-button [mat-dialog-close]="true" class="ok-button">
                <i class="mdi mdi-check-outline "></i>
            </button>
        </div>
    </div>
    
</div>



        