
    
<div class="main-dialog">
    
    <div class="mid">

        <dnd-image class="bg-image" (imgChange)="updateBackground($event)">
        </dnd-image>
        
        <div class="add-background-image whited-out"></div>
        <button mat-icon-button matTooltip="Add background banner image" (click)="addBackgroundImage()"  class="add-background-image">
            <i class=" mdi mdi-image-plus"></i>
        </button>

        <dnd-image class="profile-pic" (imgChange)="updateProfile($event)"> </dnd-image>
        <div class="add-profile-pic whited-out"></div>
        <button mat-icon-button matTooltip="Add your Profile Pic" class="add-profile-pic" (click)="addPic()"> 
            <i class=" mdi mdi-camera"></i>
        </button>

        <div class="adjust-profile-pic whited-out"></div>
        <button mat-icon-button matTooltip="Edit your Profile Pic" class="adjust-profile-pic" (click)="adjustPic()"> 
            <i class=" mdi mdi-crop-rotate "></i>
        </button>
    
        <div class="profile-name">
            <mat-form-field floatLabel="never" appearance="standard" class="short-title">
                <input matInput type="text" placeholder="Your Name" autocomplete="off" 
                        [(ngModel)]="userProfileData.userProfile.visible_name">
            </mat-form-field>
        </div>

        <div class="profile-id">
            <mat-form-field floatLabel="never" appearance="standard" class="short-title" *ngIf="isNewProfile">
                <span matPrefix>@&nbsp;</span>
                <input matInput type="text" placeholder="Your ID" autocomplete="off" 
                        (input)="idChanged()" [(ngModel)]="userProfileData.userProfile.id">
            </mat-form-field>
            <mat-form-field floatLabel="never" appearance="standard" class="short-title" *ngIf="!isNewProfile">
                <span matPrefix>@&nbsp;</span>
                <input matInput type="text" [(ngModel)]="userProfileData.userProfile.id" contentEditable="false" >
            </mat-form-field>

        </div> 

        <div *ngIf="!isValidId" class="{{ isChecking ? 'name-checking' : 'name-error' }}">
                {{ nameStatus }}
        </div>

        <div class="profile-brief">
            <text-editor [(text)]="userProfileData.userProfile.about_me" ></text-editor>
        </div>
        
    </div>

    <div class="bot">
        <div class="ok-footer">
            <button mat-icon-button [mat-dialog-close]="isValid" class="ok-button">
                <i class="mdi {{ isValid ? 'mdi-check-outline' : 'mdi-close-thick'  }} "></i>
            </button>
        </div>
    </div>
    
</div>



        