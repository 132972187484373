
export interface ThemeSettings
{
    // Theme Color Palette
    name: string;

    // primary, secondary, accent:  (50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700)
    'hex-1': string;
    'hex-a': string;

    // H is header
    // 1 is Dark.  2 is Primary.  3 is Light.  (darkest is lowest)
    // BG:  Dark blue, Blue, Light blue
    // TXT:  White, Light grey, Dark Blue
    'h-1-bg': string;    // primary dark
    'h-1-txt': string;
    'h-2-bg': string;    // primary
    'h-2-txt': string;
    'h-3-bg': string;    // primary light
    'h-3-txt': string;

    // Icons should be h-2-bg -> change to h-1-bg on Hover

    // B is body
    // 1 is Lightest.  2 is Primary.  3 is Darkest.  (darkest is lowest)
    // BG:  White, Near White, Light Grey
    // TXT:  Black, Near Black, Dark Grey,
    'b-1-bg': string;    // white
    'b-1-txt': string;
    'b-2-bg': string;    // near white
    'b-2-txt': string;
    'b-3-bg': string;    // light gre
    'b-3-txt': string;

    // Borders should be b-3-txt -> change to h-2-bg on Hover

    // A is Accent / Active
    'a-1-bg': string;  // A-1-bg <- button color
    'a-1-txt': string; // A-1-txt <- + on button color
    'a-2-bg': string;  // A-2-bg <- Hover
    'a-2-txt': string; // A-2-txt <- text
    'a-3-bg': string;  // A-3-bg <- Active/Selected
    'a-3-txt': string; // A-3-txt < - text

    // Location example:
    //   Border is b-3-txt, dark grey -> On Hover -> H-2-bg
    //   Fill is b-3-bg (light grey) -> On Hover -> A-2-bg
    //   Icon is H-2-bg (primary) -> On Hover H-1-bg
    //   Primary Text is B-2-txt -> On Hover B-1-txt
    //   Secondary Text is B-3-txt -> On Hover B-2-txt

    // e is error
    'e-bg': string;
    'e-txt': string;

    // background image tile
    'bg-1': string;
    'bg-2': string;

    'bg-overlay-1': string;
    'bg-overlay-2': string;
    'bg-overlay-3': string;

    //
    // DERIVED
    //
    'scroll-bg': string;
    'scroll-thumb': string;

    // Menu
    'menu-icon-1': string;
    'menu-icon-a': string;

    // icon button
    'icon-1': string;
    'icon-a': string;
    'action-icon-1': string;
    'action-icon-a': string;

    // two tone
    'fa-primary': string;   // h-1-bg
    'fa-secondary': string; // h-3-bg

    // Nav bar
    'nav-1-bg': string;
    'nav-1-txt': string;
    'nav-a-bg': string;
    'nav-a-txt': string;
}

export class VampireTheme implements ThemeSettings
{
    // Theme Color Palette
    name = 'vampire';

    // primary, secondary, accent:  (50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700)
    'hex-1' = '#f44336'; // "#c62828" ?
    'hex-a' = '#FF6D00'; // #607d8b 500

    // H is header
    // 1 is Dark.  2 is Primary.  3 is Light.  (darkest is lowest)
    // BG:  Dark blue, Blue, Light blue
    // TXT:  White, Light grey, Dark Blue
    'h-1-bg' = 'var( --t-1-900 )'; //  "#d32f2f";    // primary dark
    'h-1-txt' = '#ffffff';
    'h-2-bg' = 'var( --t-1-600 )';    // primary
    'h-2-txt' = '#E1E2E1';
    'h-3-bg' = 'var( --t-1-A200 )'; // "#ffcdd2";    // primary light
    'h-3-txt' = '#212121';

    // Icons should be h-2-bg -> change to h-1-bg on Hover

    // B is body
    // 1 is Lightest.  2 is Primary.  3 is Darkest.  (darkest is lowest)
    // BG:  White, Near White, Light Grey
    // TXT:  Black, Near Black, Dark Grey,
    'b-1-bg' = '#ffffff';    // white
    'b-1-txt' = '#000000';
    'b-2-bg' = '#F5F5F6';    // near white
    'b-2-txt' = '#1f1f1f';
    'b-3-bg' = '#E1E2E1';    // light grey
    'b-3-txt' = '#757575';

    // Borders should be b-3-txt -> change to h-2-bg on Hover

    // A is Accent / Active
    'a-1-bg' = 'var( --t-a-500 )'; // "#607d8b";  // A-1-bg <- button color
    'a-1-txt' = '#ffffff'; // white <- + on button color
    'a-2-bg' = 'var( --t-a-50 )'; // "#eceff1"; // --t-a-50   // A-2-bg <- Hover
    'a-2-txt' = '#212121'; // near black <- text
    'a-3-bg' = 'var( --t-a-100 )'; //  "#cfd8dc";  // --t-a-100 // A-3-bg <- Active/Selected
    'a-3-txt' = '#000000'; // darkest black < - text

    // Location example:
    //   Border is b-3-txt, dark grey -> On Hover -> H-2-bg
    //   Fill is b-3-bg (light grey) -> On Hover -> A-2-bg
    //   Icon is H-2-bg (primary) -> On Hover H-1-bg
    //   Primary Text is B-2-txt -> On Hover B-1-txt
    //   Secondary Text is B-3-txt -> On Hover B-2-txt

    // e is error
    'e-bg' = '#ffffff';
    'e-txt'= '#ba000d';

    // background image tile
    // "bg-1" = `url("/assets/img/patterns/checks.png")`;
    // "bg-2" = `url("/assets/img/patterns/waves.png")`;
    'bg-2' = `url("/assets/img/patterns/rando.png")`;
    'bg-1' = `url("/assets/img/patterns/hypnotize.png")`;
    // "bg-2" = `url("/assets/img/patterns/seigaiha.png")`;

    'bg-overlay-1' = `${this['hex-1']}22`;
    'bg-overlay-2' = `${this['hex-1']}40`;
    'bg-overlay-3' = `${this['hex-1']}88`;

    //
    // DERIVED
    //
    'scroll-bg' = 'var( --b-3-bg )';
    'scroll-thumb' = 'var( --a-1-bg )';

    // Menu
    'menu-icon-1' = 'var( --a-1-bg )';
    'menu-icon-a' = 'var( --a-2-bg )';

    // icon button, action-icon button
    'icon-1' = 'var( --h-2-bg )';
    'icon-a' = 'var( --h-1-bg )';
    'action-icon-1' = 'var( --a-1-bg )';
    'action-icon-a' = 'var( --a-2-bg )';

    // two tone
    'fa-primary' = 'var( --t-1-800 )';   // h-1-bg
    'fa-secondary' = 'var( --t-1-A400 )'; // h-3-bg

    // Nav bar << include UL / LI
    'nav-1-bg' = 'var( --b-2-bg )';
    'nav-1-txt' = 'var( --b-2-txt )';
    'nav-a-bg' = 'var( --t-a-a400 )';
    'nav-a-txt' = 'var( --b-1-txt )';
}

export class PurpleTheme implements ThemeSettings
{
    // Theme Color Palette
    name = 'starwars';

    // primary, secondary, accent:  (50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700)
    'hex-1' = '#3f51b5'; // "#c62828" ?
    'hex-a' = '#03a9f4'; // #607d8b 500

    // H is header
    // 1 is Dark.  2 is Primary.  3 is Light.  (darkest is lowest)
    // BG:  Dark blue, Blue, Light blue
    // TXT:  White, Light grey, Dark Blue
    'h-1-bg' = 'var( --t-1-800 )'; //  "#d32f2f";    // primary dark
    'h-1-txt' = '#ffffff';
    'h-2-bg' = 'var( --t-1-500 )';    // primary
    'h-2-txt' = '#E1E2E1';
    'h-3-bg' = 'var( --t-1-100 )'; // "#ffcdd2";    // primary light
    'h-3-txt' = '#757575';

    // Icons should be h-2-bg -> change to h-1-bg on Hover

    // B is body
    // 1 is Lightest.  2 is Primary.  3 is Darkest.  (darkest is lowest)
    // BG:  White, Near White, Light Grey
    // TXT:  Black, Near Black, Dark Grey,
    'b-1-bg' = '#ffffff';    // white
    'b-1-txt' = '#000000';
    'b-2-bg' = '#F5F5F6';    // near white
    'b-2-txt' = '#1f1f1f';
    'b-3-bg' = '#E1E2E1';    // light grey
    'b-3-txt' = '#757575';

    // Borders should be b-3-txt -> change to h-2-bg on Hover

    // A is Accent / Active
    'a-1-bg' = 'var( --t-a-500 )'; // "#607d8b";  // A-1-bg <- button color
    'a-1-txt' = '#ffffff'; // white <- + on button color
    'a-2-bg' = 'var( --t-a-50 )'; // "#eceff1"; // --t-a-50   // A-2-bg <- Hover
    'a-2-txt' = '#212121'; // near black <- text
    'a-3-bg' = 'var( --t-a-100 )'; //  "#cfd8dc";  // --t-a-100 // A-3-bg <- Active/Selected
    'a-3-txt' = '#000000'; // darkest black < - text

    // Location example:
    //   Border is b-3-txt, dark grey -> On Hover -> H-2-bg
    //   Fill is b-3-bg (light grey) -> On Hover -> A-2-bg
    //   Icon is H-2-bg (primary) -> On Hover H-1-bg
    //   Primary Text is B-2-txt -> On Hover B-1-txt
    //   Secondary Text is B-3-txt -> On Hover B-2-txt

    // e is error
    'e-bg' = '#ffffff';
    'e-txt'= '#ba000d';

    // background image tile
    'bg-1' = `url("/assets/img/patterns/hypnotize.png")`;
    // "bg-2" = `url("/assets/img/patterns/seigaiha.png")`;

    // "bg-2" = `url("/assets/img/patterns/waves.png")`;
    'bg-2' = `url("/assets/img/patterns/rando.png")`;
    // "bg-1" = `url("/assets/img/patterns/waves.png")`;

    'bg-overlay-1' = `${this['hex-1']}22`;
    'bg-overlay-2' = `${this['hex-1']}40`;
    'bg-overlay-3' = `${this['hex-1']}88`;

    //
    // DERIVED
    //
    'scroll-bg' = 'var( --b-3-bg )';
    'scroll-thumb' = 'var( --a-1-bg )';

    // Menu
    'menu-icon-1' = 'var( --a-1-bg )';
    'menu-icon-a' = 'var( --a-2-bg )';

    // icon button, action-icon button
    'icon-1' = 'var( --h-2-bg )';
    'icon-a' = 'var( --h-1-bg )';
    'action-icon-1' = 'var( --a-1-bg )';
    'action-icon-a' = 'var( --a-2-bg )';

    // two tone
    'fa-primary' = 'var( --t-a-400 )';   // h-1-bg
    'fa-secondary' = 'var( --t-1-A400 )'; // h-3-bg

    // Nav bar << include UL / LI
    'nav-1-bg' = 'var( --b-2-bg )';
    'nav-1-txt' = 'var( --b-2-txt )';
    'nav-a-bg' = 'var( --t-1-100 )';
    'nav-a-txt' = 'var( --b-1-txt )';
}

export class NightTheme implements ThemeSettings
{
    // Theme Color Palette
    name = 'night';

    // primary, secondary, accent:  (50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700)
    'hex-1' = '#673AB7'; // "#c62828" ?
    'hex-a' = '#29B6F6'; // #607d8b 500

    // H is header
    // 1 is Dark.  2 is Primary.  3 is Light.  (darkest is lowest)
    // BG:  Dark blue, Blue, Light blue
    // TXT:  White, Light grey, Dark Blue
    'h-1-bg' = 'var( --t-1-800 )'; //  "#d32f2f";    // primary dark
    'h-1-txt' = '#ffffff';
    'h-2-bg' = 'var( --t-1-500 )';    // primary
    'h-2-txt' = '#E1E2E1';
    'h-3-bg' = 'var( --t-1-100 )'; // "#ffcdd2";    // primary light
    'h-3-txt' = '#757575';

    // Icons should be h-2-bg -> change to h-1-bg on Hover

    // B is body
    // 1 is Lightest.  2 is Primary.  3 is Darkest.  (darkest is lowest)
    // BG:  White, Near White, Light Grey
    // TXT:  Black, Near Black, Dark Grey,
    'b-1-bg' = '#ffffff';    // white
    'b-1-txt' = '#000000';
    'b-2-bg' = '#F5F5F6';    // near white
    'b-2-txt' = '#1f1f1f';
    'b-3-bg' = '#E1E2E1';    // light grey
    'b-3-txt' = '#757575';

    // Borders should be b-3-txt -> change to h-2-bg on Hover

    // A is Accent / Active
    'a-1-bg' = 'var( --t-a-500 )'; // "#607d8b";  // A-1-bg <- button color
    'a-1-txt' = '#ffffff'; // white <- + on button color
    'a-2-bg' = 'var( --t-a-50 )'; // "#eceff1"; // --t-a-50   // A-2-bg <- Hover
    'a-2-txt' = '#212121'; // near black <- text
    'a-3-bg' = 'var( --t-a-100 )'; //  "#cfd8dc";  // --t-a-100 // A-3-bg <- Active/Selected
    'a-3-txt' = '#000000'; // darkest black < - text

    // Location example:
    //   Border is b-3-txt, dark grey -> On Hover -> H-2-bg
    //   Fill is b-3-bg (light grey) -> On Hover -> A-2-bg
    //   Icon is H-2-bg (primary) -> On Hover H-1-bg
    //   Primary Text is B-2-txt -> On Hover B-1-txt
    //   Secondary Text is B-3-txt -> On Hover B-2-txt

    // e is error
    'e-bg' = '#ffffff';
    'e-txt'= '#ba000d';

    // background image tile
    'bg-1' = `url("/assets/img/patterns/hypnotize.png")`;
    // "bg-2" = `url("/assets/img/patterns/seigaiha.png")`;

    // "bg-1" = `url("/assets/img/patterns/rando.png")`;
    'bg-2' = `url("/assets/img/patterns/rando.png")`;

    'bg-overlay-1' = `${this['hex-1']}22`;
    'bg-overlay-2' = `${this['hex-1']}40`;
    'bg-overlay-3' = `${this['hex-1']}88`;

    //
    // DERIVED
    //
    'scroll-bg' = 'var( --b-3-bg )';
    'scroll-thumb' = 'var( --a-1-bg )';

    // Menu
    'menu-icon-1' = 'var( --a-1-bg )';
    'menu-icon-a' = 'var( --a-2-bg )';

    // icon button, action-icon button
    'icon-1' = 'var( --h-2-bg )';
    'icon-a' = 'var( --h-1-bg )';
    'action-icon-1' = 'var( --a-1-bg )';
    'action-icon-a' = 'var( --a-2-bg )';

    // two tone
    'fa-primary' = 'var( --t-1-A400 )'; // h-3-bg
    'fa-secondary' = 'var( --t-a-500 )';   // h-1-bg

    // Nav bar << include UL / LI
    'nav-1-bg' = 'var( --b-2-bg )';
    'nav-1-txt' = 'var( --b-2-txt )';
    'nav-a-bg' = 'var( --t-1-100 )';
    'nav-a-txt' = 'var( --b-1-txt )';
}

export class DropletTheme implements ThemeSettings
{
    // Theme Color Palette
    name = 'droplet';

    // primary, secondary, accent:  (50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700)
    'hex-1' = '#2169F3'; // "#c62828" ?
    'hex-a' = '#00BCD4'; // #607d8b 500

    // H is header
    // 1 is Dark.  2 is Primary.  3 is Light.  (darkest is lowest)
    // BG:  Dark blue, Blue, Light blue
    // TXT:  White, Light grey, Dark Blue
    'h-1-bg' = 'var( --t-1-800 )'; //  "#d32f2f";    // primary dark
    'h-1-txt' = '#ffffff';
    'h-2-bg' = 'var( --t-1-500 )';    // primary
    'h-2-txt' = '#E1E2E1';
    'h-3-bg' = 'var( --t-1-100 )'; // "#ffcdd2";    // primary light
    'h-3-txt' = '#757575';

    // Icons should be h-2-bg -> change to h-1-bg on Hover

    // B is body
    // 1 is Lightest.  2 is Primary.  3 is Darkest.  (darkest is lowest)
    // BG:  White, Near White, Light Grey
    // TXT:  Black, Near Black, Dark Grey,
    'b-1-bg' = '#ffffff';    // white
    'b-1-txt' = '#000000';
    'b-2-bg' = '#F5F5F6';    // near white
    'b-2-txt' = '#1f1f1f';
    'b-3-bg' = '#E1E2E1';    // light grey
    'b-3-txt' = '#757575';

    // Borders should be b-3-txt -> change to h-2-bg on Hover

    // A is Accent / Active
    'a-1-bg' = 'var( --t-a-500 )'; // "#607d8b";  // A-1-bg <- button color
    'a-1-txt' = '#ffffff'; // white <- + on button color
    'a-2-bg' = 'var( --t-a-50 )'; // "#eceff1"; // --t-a-50   // A-2-bg <- Hover
    'a-2-txt' = '#212121'; // near black <- text
    'a-3-bg' = 'var( --t-a-100 )'; //  "#cfd8dc";  // --t-a-100 // A-3-bg <- Active/Selected
    'a-3-txt' = '#000000'; // darkest black < - text

    // Location example:
    //   Border is b-3-txt, dark grey -> On Hover -> H-2-bg
    //   Fill is b-3-bg (light grey) -> On Hover -> A-2-bg
    //   Icon is H-2-bg (primary) -> On Hover H-1-bg
    //   Primary Text is B-2-txt -> On Hover B-1-txt
    //   Secondary Text is B-3-txt -> On Hover B-2-txt

    // e is error
    'e-bg' = '#ffffff';
    'e-txt'= '#ba000d';

    // background image tile
    'bg-1' = `url("/assets/img/patterns/seigaiha.png")`;
    // "bg-2" = `url("/assets/img/patterns/hypnotize.png")`;
    'bg-2' = `url("/assets/img/patterns/rando.png")`;
    // "bg-1" = `url("/assets/img/patterns/hatches.png")`;

    'bg-overlay-1' = `${this['hex-1']}22`;
    'bg-overlay-2' = `${this['hex-1']}40`;
    'bg-overlay-3' = `${this['hex-1']}88`;

    //
    // DERIVED
    //
    'scroll-bg' = 'var( --b-3-bg )';
    'scroll-thumb' = 'var( --a-1-bg )';

    // Menu
    'menu-icon-1' = 'var( --a-1-bg )';
    'menu-icon-a' = 'var( --a-2-bg )';

    // icon button, action-icon button
    'icon-1' = 'var( --h-2-bg )';
    'icon-a' = 'var( --h-1-bg )';
    'action-icon-1' = 'var( --a-1-bg )';
    'action-icon-a' = 'var( --a-2-bg )';

    // two tone
    'fa-primary' = 'var( --t-1-800 )';   // h-1-bg
    'fa-secondary' = 'var( --t-1-A400 )'; // h-3-bg

    // Nav bar << include UL / LI
    'nav-1-bg' = 'var( --b-2-bg )';
    'nav-1-txt' = 'var( --b-2-txt )';
    'nav-a-bg' = 'var( --t-1-100 )';
    'nav-a-txt' = 'var( --b-1-txt )';
}

export class SunburstTheme implements ThemeSettings
{
    // Theme  Palette
    name = 'sunburst';

    // primary, secondary, accent:  (50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700)
    'hex-1' = '#FFA726'; // "#c62828" ?
    'hex-a' = '#FF3D00'; // #607d8b 500

    // H is header
    // 1 is Dark.  2 is Primary.  3 is Light.  (darkest is lowest)
    // BG:  Dark blue, Blue, Light blue
    // TXT:  White, Light grey, Dark Blue
    'h-1-bg' = 'var( --t-1-800 )'; //  "#d32f2f";    // primary dark
    'h-1-txt' = '#ffffff';
    'h-2-bg' = 'var( --t-1-500 )';    // primary
    'h-2-txt' = '#E1E2E1';
    'h-3-bg' = 'var( --t-1-100 )'; // "#ffcdd2";    // primary light
    'h-3-txt' = '#757575';

    // Icons should be h-2-bg -> change to h-1-bg on Hover

    // B is body
    // 1 is Lightest.  2 is Primary.  3 is Darkest.  (darkest is lowest)
    // BG:  White, Near White, Light Grey
    // TXT:  Black, Near Black, Dark Grey,
    'b-1-bg' = '#ffffff';    // white
    'b-1-txt' = '#000000';
    'b-2-bg' = '#F5F5F6';    // near white
    'b-2-txt' = '#1f1f1f';
    'b-3-bg' = '#E1E2E1';    // light grey
    'b-3-txt' = '#757575';

    // Borders should be b-3-txt -> change to h-2-bg on Hover

    // A is Accent / Active
    'a-1-bg' = 'var( --t-a-500 )'; // "#607d8b";  // A-1-bg <- button color
    'a-1-txt' = '#ffffff'; // white <- + on button color
    'a-2-bg' = 'var( --t-a-50 )'; // "#eceff1"; // --t-a-50   // A-2-bg <- Hover
    'a-2-txt' = '#212121'; // near black <- text
    'a-3-bg' = 'var( --t-a-100 )'; //  "#cfd8dc";  // --t-a-100 // A-3-bg <- Active/Selected
    'a-3-txt' = '#000000'; // darkest black < - text

    // Location example:
    //   Border is b-3-txt, dark grey -> On Hover -> H-2-bg
    //   Fill is b-3-bg (light grey) -> On Hover -> A-2-bg
    //   Icon is H-2-bg (primary) -> On Hover H-1-bg
    //   Primary Text is B-2-txt -> On Hover B-1-txt
    //   Secondary Text is B-3-txt -> On Hover B-2-txt

    // e is error
    'e-bg' = '#ffffff';
    'e-txt'= '#ba000d';

    // background image tile
    'bg-1' = `url("/assets/img/patterns/seigaiha.png")`;
    'bg-2' = `url("/assets/img/patterns/rando.png")`;

    'bg-overlay-1' = `${this['hex-1']}22`;
    'bg-overlay-2' = `${this['hex-1']}40`;
    'bg-overlay-3' = `${this['hex-1']}88`;

    //
    // DERIVED
    //
    'scroll-bg' = 'var( --b-3-bg )';
    'scroll-thumb' = 'var( --a-1-bg )';

    // Menu
    'menu-icon-1' = 'var( --a-1-bg )';
    'menu-icon-a' = 'var( --a-2-bg )';

    // icon button, action-icon button
    'icon-1' = 'var( --h-2-bg )';
    'icon-a' = 'var( --h-1-bg )';
    'action-icon-1' = 'var( --a-1-bg )';
    'action-icon-a' = 'var( --a-2-bg )';

    // two tone
    'fa-primary' = 'var( --t-a-500 )';   // h-1-bg
    'fa-secondary' = 'var( --t-1-A400 )'; // h-3-bg

    // Nav bar << include UL / LI
    'nav-1-bg' = 'var( --b-2-bg )';
    'nav-1-txt' = 'var( --b-2-txt )';
    'nav-a-bg' = 'var( --t-1-100 )';
    'nav-a-txt' = 'var( --b-1-txt )';
}

export class TreesTheme implements ThemeSettings
{
    // Theme  Palette
    name = 'trees';

    // primary, secondary, accent:  (50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700)
    'hex-1' = '#388e3c'; // "#c62828" ?
    'hex-a' = '#FBBC04'; // #607d8b 500

    // H is header
    // 1 is Dark.  2 is Primary.  3 is Light.  (darkest is lowest)
    // BG:  Dark blue, Blue, Light blue
    // TXT:  White, Light grey, Dark Blue
    'h-1-bg' = 'var( --t-1-800 )'; //  "#d32f2f";    // primary dark
    'h-1-txt' = '#ffffff';
    'h-2-bg' = 'var( --t-1-500 )';    // primary
    'h-2-txt' = '#E1E2E1';
    'h-3-bg' = 'var( --t-1-100 )'; // "#ffcdd2";    // primary light
    'h-3-txt' = '#757575';

    // Icons should be h-2-bg -> change to h-1-bg on Hover

    // B is body
    // 1 is Lightest.  2 is Primary.  3 is Darkest.  (darkest is lowest)
    // BG:  White, Near White, Light Grey
    // TXT:  Black, Near Black, Dark Grey,
    'b-1-bg' = '#ffffff';    // white
    'b-1-txt' = '#000000';
    'b-2-bg' = '#F5F5F6';    // near white
    'b-2-txt' = '#1f1f1f';
    'b-3-bg' = '#E1E2E1';    // light grey
    'b-3-txt' = '#757575';

    // Borders should be b-3-txt -> change to h-2-bg on Hover

    // A is Accent / Active
    'a-1-bg' = 'var( --t-a-500 )'; // "#607d8b";  // A-1-bg <- button color
    'a-1-txt' = '#ffffff'; // white <- + on button color
    'a-2-bg' = 'var( --t-a-50 )'; // "#eceff1"; // --t-a-50   // A-2-bg <- Hover
    'a-2-txt' = '#212121'; // near black <- text
    'a-3-bg' = 'var( --t-a-100 )'; //  "#cfd8dc";  // --t-a-100 // A-3-bg <- Active/Selected
    'a-3-txt' = '#000000'; // darkest black < - text

    // Location example:
    //   Border is b-3-txt, dark grey -> On Hover -> H-2-bg
    //   Fill is b-3-bg (light grey) -> On Hover -> A-2-bg
    //   Icon is H-2-bg (primary) -> On Hover H-1-bg
    //   Primary Text is B-2-txt -> On Hover B-1-txt
    //   Secondary Text is B-3-txt -> On Hover B-2-txt

    // e is error
    'e-bg' = '#ffffff';
    'e-txt'= '#ba000d';

    // background image tile
    'bg-1' = `url("/assets/img/patterns/seigaiha.png")`;
    'bg-2' = `url("/assets/img/patterns/rando.png")`;

    'bg-overlay-1' = `${this['hex-1']}22`;
    'bg-overlay-2' = `${this['hex-1']}40`;
    'bg-overlay-3' = `${this['hex-1']}88`;

    //
    // DERIVED
    //
    'scroll-bg' = 'var( --b-3-bg )';
    'scroll-thumb' = 'var( --a-1-bg )';

    // Menu
    'menu-icon-1' = 'var( --a-1-bg )';
    'menu-icon-a' = 'var( --a-2-bg )';

    // icon button, action-icon button
    'icon-1' = 'var( --h-2-bg )';
    'icon-a' = 'var( --h-1-bg )';
    'action-icon-1' = 'var( --a-1-bg )';
    'action-icon-a' = 'var( --a-2-bg )';

    // two tone
    'fa-primary' = 'var( --t-1-800 )';   // h-1-bg
    'fa-secondary' = 'var( --t-1-A400 )'; // h-3-bg

    // Nav bar << include UL / LI
    'nav-1-bg' = 'var( --b-2-bg )';
    'nav-1-txt' = 'var( --b-2-txt )';
    'nav-a-bg' = 'var( --t-1-100 )';
    'nav-a-txt' = 'var( --b-1-txt )';
}


export class CoffeeTheme implements ThemeSettings
{
    // Theme  Palette
    name = 'coffee';

    // primary, secondary, accent:  (50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700)
    'hex-1' = '#795548'; // "#c62828" ?
    'hex-a' = '#FFC400'; // #607d8b 500

    // H is header
    // 1 is Dark.  2 is Primary.  3 is Light.  (darkest is lowest)
    // BG:  Dark blue, Blue, Light blue
    // TXT:  White, Light grey, Dark Blue
    'h-1-bg' = 'var( --t-1-800 )'; //  "#d32f2f";    // primary dark
    'h-1-txt' = '#ffffff';
    'h-2-bg' = 'var( --t-1-500 )';    // primary
    'h-2-txt' = '#E1E2E1';
    'h-3-bg' = 'var( --t-1-100 )'; // "#ffcdd2";    // primary light
    'h-3-txt' = '#757575';

    // Icons should be h-2-bg -> change to h-1-bg on Hover

    // B is body
    // 1 is Lightest.  2 is Primary.  3 is Darkest.  (darkest is lowest)
    // BG:  White, Near White, Light Grey
    // TXT:  Black, Near Black, Dark Grey,
    'b-1-bg' = '#ffffff';    // white
    'b-1-txt' = '#000000';
    'b-2-bg' = '#F5F5F6';    // near white
    'b-2-txt' = '#1f1f1f';
    'b-3-bg' = '#E1E2E1';    // light grey
    'b-3-txt' = '#757575';

    // Borders should be b-3-txt -> change to h-2-bg on Hover

    // A is Accent / Active
    'a-1-bg' = 'var( --t-a-500 )'; // "#607d8b";  // A-1-bg <- button color
    'a-1-txt' = '#ffffff'; // white <- + on button color
    'a-2-bg' = 'var( --t-a-50 )'; // "#eceff1"; // --t-a-50   // A-2-bg <- Hover
    'a-2-txt' = '#212121'; // near black <- text
    'a-3-bg' = 'var( --t-a-100 )'; //  "#cfd8dc";  // --t-a-100 // A-3-bg <- Active/Selected
    'a-3-txt' = '#000000'; // darkest black < - text

    // Location example:
    //   Border is b-3-txt, dark grey -> On Hover -> H-2-bg
    //   Fill is b-3-bg (light grey) -> On Hover -> A-2-bg
    //   Icon is H-2-bg (primary) -> On Hover H-1-bg
    //   Primary Text is B-2-txt -> On Hover B-1-txt
    //   Secondary Text is B-3-txt -> On Hover B-2-txt

    // e is error
    'e-bg' = '#ffffff';
    'e-txt'= '#ba000d';

    // background image tile
    'bg-1' = `url("/assets/img/patterns/seigaiha.png")`;
    'bg-2' = `url("/assets/img/patterns/rando.png")`;

    'bg-overlay-1' = `${this['hex-1']}22`;
    'bg-overlay-2' = `${this['hex-1']}40`;
    'bg-overlay-3' = `${this['hex-1']}88`;

    //
    // DERIVED
    //
    'scroll-bg' = 'var( --b-3-bg )';
    'scroll-thumb' = 'var( --a-1-bg )';

    // Menu
    'menu-icon-1' = 'var( --a-1-bg )';
    'menu-icon-a' = 'var( --a-2-bg )';

    // icon button, action-icon button
    'icon-1' = 'var( --h-2-bg )';
    'icon-a' = 'var( --h-1-bg )';
    'action-icon-1' = 'var( --a-1-bg )';
    'action-icon-a' = 'var( --a-2-bg )';

    // two tone
    // "fa-secondary" = "var( --t-a-500 )";   // h-1-bg
    'fa-primary' = 'var( --t-1-800 )';   // h-1-bg
    'fa-secondary' = 'var( --t-1-A400 )'; // h-3-bg

    // Nav bar << include UL / LI
    'nav-1-bg' = 'var( --b-2-bg )';
    'nav-1-txt' = 'var( --b-2-txt )';
    'nav-a-bg' = 'var( --t-1-100 )';
    'nav-a-txt' = 'var( --b-1-txt )';
}
