

//
// Firebase Realtime Database Tables
//
export enum DataTablePathList
{
    POSTS = "posts",
    USER_PROFILES = "profiles", 
    WHITE_LIST = "white_list",
    USER_ID_MAPPINGS = "user_mappings",
    STORAGE_ITEM = "storage_item",
}

// intended to be stored as JSON.stringify() in cache and storage
// intended to be stored (without content) in /storage_items/id in DB
export interface StorageItem
{
    id: string; // Random key
    type: STORAGE_TYPE;
    author_id: string;  // @user_id
    cr_dt: number;
    lt_dt: number;
    content_name: string;
    content: any;
}

export enum STORAGE_TYPE
{
    HTML = "html",
    PNG = "png",
    ANY = "any",
}

export interface UserIdMapping
{
    id: string;   // @user_id
    uid: string;  // firebase user id
}

export interface Post // POSTS
{
    // Post ID
    id: string; // Random key
    type: POST_TYPE;
    is_deleted?: boolean;

    // who and when
    cr_dt: number;
    lt_dt: number;

    author_id: string; // @user_id
    author_name: string;  

    // Who can see it
    permission_groups?: string[]; // none is everyone.  else limit by users in group

    // If there is a title
    title?: string;

    // If there is a comment
    short_txt: string;

    // If there is a single image
    img_storage_id?: string;

    // For long post format only
    long_txt_storage_id?: string;

    // If there is a review
    review?: Review;

    // If there is a place
    place?: Place;

    // Still have to manage likes and comments... later.
}

export interface Review
{
    tbd: string;
}
export interface Place
{
    tbd: string;
}

export enum POST_TYPE
{
    REVIEW = 'Review',
    SHORT = 'Short',
    LONG = 'Long',
    PICTURE = 'Picture',
    TIL = 'Learning',
    PLACES = 'Place',
    OPINION = 'Opinion',
}

export interface UserProfile
{
    id: string; // @user_id
    is_deleted?: boolean;

    firebase_uid: string;  // fire base user id

    visible_name: string;
    about_me?: string;

    bg_storage_id?: string;
    profile_storage_id?: string;
}

export interface PermissionGroup
{
    name: string;
    entries: PermissionEntry[];
}

export interface PermissionEntry
{
    id: string;  // @user_id
    provider: string;
    uid: string;
}

export interface WhiteListEntry
{
    id: string;
    login_id: string;
}

export interface PageRequestPayload
{
    context: string;
    type: PAGE_REQ_TYPE;
    from_dt?: number;
    to_dt?: number;
}

export interface PageResponsePayload
{
    context: string;
    posts: Post[];
    from_dt?: number;
    to_dt?: number;
}

export enum PAGE_REQ_TYPE
{
    USER = "user",
    ID = "id",
}

export interface BasicResponse
{
    success: boolean;
    reason?: string;
}