import { Component } from '@angular/core';

@Component({
  selector: 'loader-card',
  templateUrl: './LoaderCardComponent.html',
  styleUrls: ['./LoaderCardComponent.scss']
})
export class LoaderCardComponent {
    constructor() { }
}
