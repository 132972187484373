
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuillModule } from 'ngx-quill';
import { LayoutComponent } from '../layout/LayoutComponent';
import { LayoutModule } from '@angular/cdk/layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { ActionBarComponent } from '../layout/ActionBar/ActionBarComponent';
import { ActionItemComponent } from '../layout/ActionItem/ActionItemComponent';
import { SvgIconComponent } from '../layout/SvgIcon/SvgIconComponent';
import { SvgButtonComponent } from '../layout/SvgButton/SvgButtonComponent';
import { ProfileCardComponent } from '../panels/ProfileCard/ProfileCardComponent';
import { ShortPostDialogComponent } from '../panels/ShortPostDialog/ShortPostDialogComponent';
import { PostCardComponent } from '../panels/PostCard/PostCardComponent';
import { LoaderCardComponent } from '../panels/LoaderCard/LoaderCardComponent';
import { TextEditorComponent } from '../panels/TextEditor/TextEditorComponent';
import { TextDisplayComponent } from '../panels/TextDisplay/TextDisplayComponent';
import { ProfileDialogComponent } from '../panels/ProfileDialog/ProfileDialogComponent';
import { DnDImageComponent } from '../panels/DnDImage/DnDImageComponent';
import { CropDialogComponent } from '../panels/CropDialog/CropDialogComponent';
import { LongPostDialogComponent } from '../panels/LongPostDialog/LongPostDialogComponent';


const components = [
    LayoutComponent,
    ActionBarComponent,
    ActionItemComponent,
    SvgIconComponent,
    SvgButtonComponent,
    PostCardComponent,
    ProfileCardComponent,
    LoaderCardComponent,
    TextEditorComponent,
    TextDisplayComponent,
    ShortPostDialogComponent,
    ProfileDialogComponent,
    DnDImageComponent,
    CropDialogComponent,
];

const dialogs = [
    ShortPostDialogComponent,
    ProfileDialogComponent,
    CropDialogComponent,
    LongPostDialogComponent,
];

const modules = [ 
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    LayoutModule,
    MatFormFieldModule,
    MatSnackBarModule,
    RouterModule,
    QuillModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    DragDropModule,
  ];

@NgModule({
    declarations: [...components, ...dialogs], 
    imports: [...modules],
    exports: [
      ...components,
      ...modules,
      ...dialogs
    ],
    entryComponents: [
        ...dialogs
    ]
})
export class SharedModule {} 

