
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthMgr } from "./provider/AuthMgr";

const routes: Routes = [

    { path: "404", loadChildren: () => import('./pages/Error404/Error404Module').then(m => m.Error404Module) },
    { path: "login", loadChildren: () => import('./pages/Login/LoginModule').then(m => m.LoginModule) },
    { path: "waitlist", loadChildren: () => import('./pages/WaitList/WaitListModule').then(m => m.WaitListModule) },
    { path: "welcome", loadChildren: () => import('./pages/Welcome/WelcomeModule').then(m => m.WelcomeModule) },
	{ path: "", loadChildren: () => import('./pages/Main/MainModule').then(m => m.MainModule), canActivate: [ AuthMgr ] },
    { path: "user/:id", loadChildren: () => import('./pages/User/UserPageModule').then(m => m.UserPageModule), canActivate: [ AuthMgr ] },
    { path: "user/:id/:postId", loadChildren: () => import('./pages/User/UserPageModule').then(m => m.UserPageModule), canActivate: [ AuthMgr ] },
    { path: "**", redirectTo: "404" },
];

@NgModule({
	imports: [RouterModule.forRoot( routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
