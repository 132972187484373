

<svg aria-hidden="true" focusable="false" *ngIf="viewbox !== ''"
    xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="viewbox">
    <g>
         <!-- class="fa-group"> -->
        <path fill="currentColor" [attr.d]="secondaryPath" class="fa-secondary"></path>
        <path fill="currentColor" [attr.d]="primaryPath" class="fa-primary"></path>
    </g>
</svg>
