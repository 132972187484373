
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { PostDialogInfo } from './PostMgr';

@Component({
    templateUrl: 'LongPostDialogComponent.html',
    styleUrls: ['LongPostDialogComponent.scss']
})
export class LongPostDialogComponent
{
    internalPost: PostDialogInfo;

    constructor( public dialogRef: MatDialogRef<LongPostDialogComponent>,
            @Inject( MAT_DIALOG_DATA ) public data: PostDialogInfo )
    {
        this.internalPost = data;
    }
}
