import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'svg-icon',
    templateUrl: './SvgIconComponent.html',
    styleUrls: ['./SvgIconComponent.scss']
})
export class SvgIconComponent implements OnInit
{
    static CARROT: SvgDetails = {
        name: 'carrot', viewbox: '0 0 512 512',
        primaryPath: 'M239 369.9L103.5 234.4a23.9 23.9 0 0 1 0-33.9l17-17a23.9 23.9 0 0 1 33.9 0L256 285.1l101.6-101.6a23.9 23.9 0 0 1 33.9 0l17 17a23.9 23.9 0 0 1 0 33.9L273 369.9a24 24 0 0 1-34 0z',
        secondaryPath: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm152.5 226.4L273 369.9a24 24 0 0 1-34 0L103.5 234.4a23.9 23.9 0 0 1 0-33.9l17-17a23.9 23.9 0 0 1 33.9 0L256 285.1l101.6-101.6a23.9 23.9 0 0 1 33.9 0l17 17a23.9 23.9 0 0 1 0 33.9z'
    };

    static LEFT: SvgDetails = {
        name: 'left', viewbox: '0 0 448 512',
        primaryPath: 'M32.11 239l135.55-136a23.77 23.77 0 0 1 33.79 0L224 125.74a23.94 23.94 0 0 1 0 33.89L127.89 256l96 96.47a23.94 23.94 0 0 1 0 33.89L201.35 409a23.77 23.77 0 0 1-33.79 0L32 273a24 24 0 0 1 .11-34z',
        secondaryPath: 'M224 239l135.61-136a23.78 23.78 0 0 1 33.8 0L416 125.64a23.94 23.94 0 0 1 0 33.89l-96.16 96.37L416 352.27a23.94 23.94 0 0 1 0 33.89L393.53 409a23.78 23.78 0 0 1-33.8 0L224.12 273a23.94 23.94 0 0 1-.1-34z'
    };

    static RIGHT: SvgDetails = {
        name: 'right', viewbox: '0 0 448 512',
        primaryPath: 'M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z',
        secondaryPath: 'M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z'
    };

    static UP: SvgDetails = {
        name: 'up', viewbox: '0 0 320 512',
        primaryPath: 'M177 64.11l136 135.55a23.77 23.77 0 0 1 0 33.79L290.26 256a23.94 23.94 0 0 1-33.89 0L160 159.89l-96.47 96a23.94 23.94 0 0 1-33.89 0L7.05 233.35a23.77 23.77 0 0 1 0-33.79L143 64a24 24 0 0 1 34 .11z',
        secondaryPath: 'M177 256l136 135.63a23.78 23.78 0 0 1 0 33.8L290.36 448a23.94 23.94 0 0 1-33.89 0l-96.37-96.16L63.73 448a23.94 23.94 0 0 1-33.89 0L7.05 425.53a23.78 23.78 0 0 1 0-33.8L143 256.12a23.94 23.94 0 0 1 34-.1z'
    };

    static DOWN: SvgDetails = {
        name: 'down', viewbox: '0 0 320 512',
        primaryPath: 'M143 447.89L7.05 312.34a23.77 23.77 0 0 1 0-33.79L29.74 256a23.94 23.94 0 0 1 33.89 0L160 352.11l96.47-96a23.94 23.94 0 0 1 33.89 0L313 278.65a23.77 23.77 0 0 1 0 33.79L177 448a24 24 0 0 1-34-.11z',
        secondaryPath: 'M143 256L7.05 120.37a23.78 23.78 0 0 1 0-33.8L29.64 64a23.94 23.94 0 0 1 33.89 0l96.37 96.13L256.27 64a23.94 23.94 0 0 1 33.89 0L313 86.47a23.78 23.78 0 0 1 0 33.8L177 255.88a23.94 23.94 0 0 1-34 .1z'
    };

    static SEARCH: SvgDetails = {
        name: 'search', viewbox: '0 0 512 512',
        primaryPath: 'M504.9 476.7L476.6 505a23.9 23.9 0 0 1-33.9 0L343 405.3a24 24 0 0 1-7-17V372l36-36h16.3a24 24 0 0 1 17 7l99.7 99.7a24.11 24.11 0 0 1-.1 34z',
        secondaryPath: 'M208 80a128 128 0 1 1-90.51 37.49A127.15 127.15 0 0 1 208 80m0-80C93.12 0 0 93.12 0 208s93.12 208 208 208 208-93.12 208-208S322.88 0 208 0z'
    };

    // Account
    static ACCOUNT: SvgDetails = {
        name: 'account', viewbox: '0 0 24 24',
        primaryPath: 'M6 4v16h11.6l-2.85-2.85c-.83.55-1.79.83-2.75.83-1.28 0-2.55-.49-3.53-1.46-1.95-1.95-1.95-5.11 0-7.05C9.45 8.49 10.72 8 12 8c1.28 0 2.55.49 3.53 1.46 1.71 1.71 1.92 4.34.64 6.28L18 17.58V9l-5-5H6z',
        secondaryPath: 'M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 15.58l-1.84-1.84c1.28-1.94 1.07-4.57-.64-6.28C14.55 8.49 13.28 8 12 8c-1.28 0-2.55.49-3.53 1.46-1.95 1.95-1.95 5.11 0 7.05.97.97 2.25 1.46 3.53 1.46.96 0 1.92-.28 2.75-.83L17.6 20H6V4h7l5 5v8.58zm-3.01-4.59c0 .8-.31 1.55-.88 2.11-.56.56-1.31.88-2.11.88s-1.55-.31-2.11-.88c-.56-.56-.88-1.31-.88-2.11s.31-1.55.88-2.11S11.2 10 12 10s1.55.31 2.11.88c.57.56.88 1.31.88 2.11z'
    };

    static MOON: SvgDetails = {
        name: 'moon', viewbox: '0 0 512 512',
        primaryPath: 'M332.2 426.4c8.1-1.6 13.9 8 8.6 14.5a191.18 191.18 0 0 1-149 71.1C85.8 512 0 426 0 320c0-120 108.7-210.6 227-188.8 8.2 1.6 10.1 12.6 2.8 16.7a150.3 150.3 0 0 0-76.1 130.8c0 94 85.4 165.4 178.5 147.7z',
        secondaryPath: 'M320 32L304 0l-16 32-32 16 32 16 16 32 16-32 32-16zm138.7 149.3L432 128l-26.7 53.3L352 208l53.3 26.7L432 288l26.7-53.3L512 208z'
    }

    // Tracker
    static TRACKER: SvgDetails = {
        name: 'tracker', viewbox: '0 0 24 24',
        primaryPath: 'M6 4v16h11.6l-2.85-2.85c-.83.55-1.79.83-2.75.83-1.28 0-2.55-.49-3.53-1.46-1.95-1.95-1.95-5.11 0-7.05C9.45 8.49 10.72 8 12 8c1.28 0 2.55.49 3.53 1.46 1.71 1.71 1.92 4.34.64 6.28L18 17.58V9l-5-5H6z',
        secondaryPath: 'M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 15.58l-1.84-1.84c1.28-1.94 1.07-4.57-.64-6.28C14.55 8.49 13.28 8 12 8c-1.28 0-2.55.49-3.53 1.46-1.95 1.95-1.95 5.11 0 7.05.97.97 2.25 1.46 3.53 1.46.96 0 1.92-.28 2.75-.83L17.6 20H6V4h7l5 5v8.58zm-3.01-4.59c0 .8-.31 1.55-.88 2.11-.56.56-1.31.88-2.11.88s-1.55-.31-2.11-.88c-.56-.56-.88-1.31-.88-2.11s.31-1.55.88-2.11S11.2 10 12 10s1.55.31 2.11.88c.57.56.88 1.31.88 2.11z'
    };

    // Qualify
    static QUALIFY: SvgDetails = {
        name: 'qualify', viewbox: '0 0 384 512',
        primaryPath: 'M192 288a32 32 0 1 0 32 32 32 32 0 0 0-32-32zm2.67-216a88.14 88.14 0 0 0-85.81 68.45A15.92 15.92 0 0 0 124.3 160h16.84c7.22 0 13.18-5 15.33-11.88a40.06 40.06 0 0 1 38.2-28.12c26 0 40 20.61 40 40 0 16-20.06 27.19-50.67 42.5a24.09 24.09 0 0 0-13.33 21.5v16.16A15.84 15.84 0 0 0 186.51 256h16.32a15.84 15.84 0 0 0 15.84-15.84v-1.43c31.25-16.26 64-37.78 64-78.73 0-43.25-32.92-88-88-88z',
        secondaryPath: 'M192 0C86.4 0 0 86.4 0 192c0 76.8 25.6 99.2 172.8 310.4a24 24 0 0 0 38.4 0C358.4 291.2 384 268.8 384 192 384 86.4 297.6 0 192 0zm0 352a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm26.67-113.27v1.43A15.84 15.84 0 0 1 202.83 256h-16.32a15.84 15.84 0 0 1-15.84-15.84V224A24.09 24.09 0 0 1 184 202.5c30.61-15.31 50.67-26.54 50.67-42.5 0-19.39-14-40-40-40a40.06 40.06 0 0 0-38.2 28.12C154.32 155 148.36 160 141.14 160H124.3a15.92 15.92 0 0 1-15.44-19.55A88.14 88.14 0 0 1 194.67 72c55.08 0 88 44.75 88 88 0 41-32.75 62.47-64 78.73z'
    };

    // Towers
    static TOWERS: SvgDetails = {
        name: 'towers', viewbox: '0 0 640 512',
        primaryPath: 'M503.26 477.53l-130.5-313.41a64 64 0 1 0-105.52 0l-130.5 313.41a16 16 0 0 0 8.61 20.92l29.51 12.31a16 16 0 0 0 20.91-8.61L245 384h150l49.2 118.15a16 16 0 0 0 20.91 8.61l29.51-12.31a16 16 0 0 0 8.64-20.92zM271.62 320L320 203.81 368.38 320z',
        secondaryPath: 'M184.67 64h-33.73a16 16 0 0 0-15.41 11.23 188.52 188.52 0 0 0 0 105.53A16 16 0 0 0 150.94 192h33.73a15.76 15.76 0 0 0 14.86-21.18 125.2 125.2 0 0 1 0-85.64A15.75 15.75 0 0 0 184.67 64zM89.92 23.34A15.83 15.83 0 0 0 76 0H40.63a16.18 16.18 0 0 0-14.74 9.31A285.66 285.66 0 0 0 0 128c0 24.75 3.12 68.33 26.69 118.86A16.1 16.1 0 0 0 41.3 256h34.84a15.84 15.84 0 0 0 14-23.37c-49.83-93.32-16.76-178.15-.22-209.29zm524.14-14A16.17 16.17 0 0 0 599.33 0h-35.42a15.81 15.81 0 0 0-14 23.25c18.27 34.29 48.42 119.42.28 209.23A15.87 15.87 0 0 0 564.08 256h35.23a16.17 16.17 0 0 0 14.69-9.29 284.5 284.5 0 0 0 0-237.42zM489.06 64h-33.73a15.76 15.76 0 0 0-14.86 21.18 125.2 125.2 0 0 1 0 85.64A15.75 15.75 0 0 0 455.33 192h33.73a16 16 0 0 0 15.41-11.24 188.48 188.48 0 0 0 0-105.52A15.94 15.94 0 0 0 489.06 64z'
    };

    // Expanse
    static EXPANSE: SvgDetails = {
        name: 'expanse', viewbox: '0 0 496 512',
        primaryPath: 'M321.39,297.36A32,32,0,0,0,298.76,288H232c-9.41-4.71-21.48-32-32-32a67.72,67.72,0,0,1-30.31-7.16l-11.08-5.54a12,12,0,0,1,1.56-22l31.17-10.39A16,16,0,0,1,206.9,214l9.28,8.06a8,8,0,0,0,5.24,2h5.64a8,8,0,0,0,7.15-11.58l-15.59-31.19A8,8,0,0,1,220.2,172l9.92-9.65A8,8,0,0,1,235.7,160h9a8,8,0,0,0,5.66-2.34l8-8a8,8,0,0,0,0-11.31l-4.69-4.69a8,8,0,0,1,0-11.31L264,112l4.69-4.68a16,16,0,0,0,0-22.63h0l-24.4-24.4a12.38,12.38,0,0,0-9.55-3.61c-2.53.17-5.05.38-7.58.65A12.41,12.41,0,0,0,216,69.66a16.35,16.35,0,0,1-11.59,15.83,16,16,0,0,1-11.57-1.07,66.09,66.09,0,0,1-16-11.24L136.26,34.54A249,249,0,0,0,56,99v45.71a50,50,0,0,0,8.55,27.95c11.72,17.39,28.38,42.07,35.67,52.77a114.79,114.79,0,0,0,18.06,20.74l.8.72a144.26,144.26,0,0,0,31.65,21.75c14,7.05,34.44,18.16,48.81,26.11a31.9,31.9,0,0,1,16.46,28v32a32,32,0,0,0,9.37,22.63c15,15,24.32,38.63,22.63,51.25V457.7a21,21,0,0,0,23.49,20.85c1.75-.21,3.49-.44,5.23-.7a20.91,20.91,0,0,0,17.17-15.76L308,404.46c2-5.49,3.26-11.21,4.77-16.87A23.9,23.9,0,0,1,319,376.88c3.32-3.33,7.41-7.4,11.31-11.28a46.46,46.46,0,0,0,13.72-33A30.49,30.49,0,0,0,335.1,311ZM477.67,162.27l-36.51,3.15a76.22,76.22,0,0,0-27.48,7.74,24.05,24.05,0,0,0-9.24,8.15l-19.59,29.38a24,24,0,0,0,0,26.62l18,27a24,24,0,0,0,10.54,8.78l20.52,10.1,55.64,29.22a249.21,249.21,0,0,0-11.88-150.14Z',
        secondaryPath: 'M489.55,312.41C464,422.22,365.59,504,248,504,111,504,0,393,0,256A247,247,0,0,1,56,99v45.71a50,50,0,0,0,8.55,27.95c11.72,17.39,28.38,42.07,35.67,52.77a114.79,114.79,0,0,0,18.06,20.74l.8.72a144.26,144.26,0,0,0,31.65,21.75c14,7.05,34.44,18.16,48.81,26.11a31.9,31.9,0,0,1,16.46,28v32a32,32,0,0,0,9.37,22.63c15,15,24.32,38.63,22.63,51.25V457.7a21,21,0,0,0,23.49,20.85c1.75-.21,3.49-.44,5.23-.7a20.91,20.91,0,0,0,17.17-15.76L308,404.46c2-5.49,3.26-11.21,4.77-16.87A23.9,23.9,0,0,1,319,376.88c3.32-3.33,7.41-7.4,11.31-11.28a46.46,46.46,0,0,0,13.72-33A30.49,30.49,0,0,0,335.1,311l-13.71-13.67A32,32,0,0,0,298.76,288H232c-9.41-4.71-21.48-32-32-32a67.72,67.72,0,0,1-30.31-7.16l-11.08-5.54a12,12,0,0,1,1.56-22l31.17-10.39A16,16,0,0,1,206.9,214l9.28,8.06a8,8,0,0,0,5.24,2h5.64a8,8,0,0,0,7.15-11.58l-15.59-31.19A8,8,0,0,1,220.2,172l9.92-9.65A8,8,0,0,1,235.7,160h9a8,8,0,0,0,5.66-2.34l8-8a8,8,0,0,0,0-11.31l-4.69-4.69a8,8,0,0,1,0-11.31L264,112l4.69-4.68a16,16,0,0,0,0-22.63h0l-24.4-24.4a12.38,12.38,0,0,0-9.55-3.61c-2.53.17-5.05.38-7.58.65A12.41,12.41,0,0,0,216,69.66a16.35,16.35,0,0,1-11.59,15.83,16,16,0,0,1-11.57-1.07,66.09,66.09,0,0,1-16-11.24L136.26,34.54A247,247,0,0,1,248,8C351.83,8,440.71,71.76,477.67,162.27l-36.51,3.15a76.22,76.22,0,0,0-27.48,7.74,24.05,24.05,0,0,0-9.24,8.15l-19.59,29.38a24,24,0,0,0,0,26.62l18,27a24,24,0,0,0,10.54,8.78l20.52,10.1Z'
    };

    // Text
    static TEXT: SvgDetails = {
        name: 'text', viewbox: '0 0 512 512',
        primaryPath: 'M397.3 237.3l-21.9-18.8a5.29 5.29 0 0 1-2.1-3.8c0-3.1 4.4-6.6 10.4-6.6H396a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8h-12.2c-23.4 0-42.4 17.3-42.4 38.6a37 37 0 0 0 13.3 28.1l21.9 18.8a5.29 5.29 0 0 1 2.1 3.8c0 3.1-4.4 6.6-10.4 6.6H356a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h12.3c23.4 0 42.4-17.3 42.3-38.6a37 37 0 0 0-13.3-28.1zM304 176h-16a16 16 0 0 0-14.3 8.8L256 220.2l-17.7-35.4A15.92 15.92 0 0 0 224 176h-16a16 16 0 0 0-16 16v104a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8v-68.2l24.9 55.8a8 8 0 0 0 14.3 0l24.8-55.8V296a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V192a16 16 0 0 0-16-16zm-146.7 61.3l-21.9-18.8a5.29 5.29 0 0 1-2.1-3.8c0-3.1 4.4-6.6 10.4-6.6H156a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8h-12.2c-23.4 0-42.4 17.3-42.4 38.6a37 37 0 0 0 13.3 28.1l21.9 18.8a5.29 5.29 0 0 1 2.1 3.8c0 3.1-4.4 6.6-10.4 6.6H116a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h12.2c23.4 0 42.5-17.3 42.4-38.6a37 37 0 0 0-13.3-28.1z',
        secondaryPath: 'M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5a8 8 0 0 0-1.5 8.7A7.83 7.83 0 0 0 8 480c66.3 0 116-31.8 140.6-51.4A305 305 0 0 0 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zM128.2 304H116a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h12.3c6 0 10.4-3.5 10.4-6.6a5.29 5.29 0 0 0-2.1-3.8l-21.9-18.8a37 37 0 0 1-13.3-28.1c0-21.3 19-38.6 42.4-38.6H156a8 8 0 0 1 8 8v16a8 8 0 0 1-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6a5.29 5.29 0 0 0 2.1 3.8l21.9 18.8a37 37 0 0 1 13.3 28.1c.1 21.3-19 38.6-42.4 38.6zm191.8-8a8 8 0 0 1-8 8h-16a8 8 0 0 1-8-8v-68.2l-24.8 55.8a8 8 0 0 1-14.3 0L224 227.8V296a8 8 0 0 1-8 8h-16a8 8 0 0 1-8-8V192a16 16 0 0 1 16-16h16a15.92 15.92 0 0 1 14.3 8.8l17.7 35.4 17.7-35.4A16 16 0 0 1 288 176h16a16 16 0 0 1 16 16zm48.3 8H356a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h12.3c6 0 10.4-3.5 10.4-6.6a5.29 5.29 0 0 0-2.1-3.8l-21.9-18.8a37 37 0 0 1-13.3-28.1c0-21.3 19-38.6 42.4-38.6H396a8 8 0 0 1 8 8v16a8 8 0 0 1-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6a5.29 5.29 0 0 0 2.1 3.8l21.9 18.8a37 37 0 0 1 13.3 28.1c.1 21.3-18.9 38.6-42.3 38.6z'
    };

    // Agents
    static AGENTS: SvgDetails = {
        name: 'agents', viewbox: '0 0 448 512',
        primaryPath: 'M320 352h-23.1a174.1 174.1 0 0 1-145.8 0H128A128 128 0 0 0 0 480a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32 128 128 0 0 0-128-128zm-175.65-60.53c-.06-1.17-.35-2.28-.35-3.47a64.07 64.07 0 0 1 64-64h32a64 64 0 0 1 55.41 32H304a48.05 48.05 0 0 0 48-48v-16a128 128 0 0 0-256 0c0 40.42 19.1 76 48.35 99.47z',
        secondaryPath: 'M416 192v16a112.15 112.15 0 0 1-112 112h-96a32 32 0 0 1 0-64h32a32 32 0 0 1 32 32h32a80.09 80.09 0 0 0 80-80v-16c0-88.22-71.78-160-160-160S64 103.78 64 192v16a16 16 0 0 1-32 0v-16C32 86.13 118.13 0 224 0s192 86.13 192 192z'
    };

    // Support
    static SUPPORT: SvgDetails = {
        name: 'support', viewbox: '0 0 512 512',
        primaryPath: 'M512 48c0 256.5-207.9 464-464 464a24 24 0 0 1-23.4-18.6l-24-104a24.29 24.29 0 0 1 14-27.6l112-48a24 24 0 0 1 28 6.9l49.6 60.6a370.61 370.61 0 0 0 177.2-177.2l-60.6-49.6a23.94 23.94 0 0 1-6.9-28l48-112A24.16 24.16 0 0 1 389.41.61l104 24A24 24 0 0 1 512 48z',
        secondaryPath: 'M144 16v64h64a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16h-64v64a16 16 0 0 1-16 16H96a16 16 0 0 1-16-16v-64H16a16 16 0 0 1-16-16V96a16 16 0 0 1 16-16h64V16A16 16 0 0 1 96 0h32a16 16 0 0 1 16 16z'
    };

    // NOC
    static NOC: SvgDetails = {
        name: 'noc', viewbox: '0 0 640 512',
        primaryPath: 'M576 384a63.84 63.84 0 0 0-38.3 13l-96-57.6a109.91 109.91 0 0 0 6.3-35.5 111.94 111.94 0 0 0-112-112 108.64 108.64 0 0 0-24.4 2.9l-40.8-87.4A63.84 63.84 0 1 0 224 128c1.1 0 2.1-.3 3.2-.3l41 87.8C241.5 235.9 224 267.8 224 304a111.71 111.71 0 0 0 193.2 76.7l95.8 57.5a63.87 63.87 0 1 0 63-54.2zm-240-32a48 48 0 1 1 48-48 48 48 0 0 1-48 48z',
        secondaryPath: 'M64 240a64 64 0 1 0 64 64 64.06 64.06 0 0 0-64-64zm88 80h48v-32h-48zm294.4-106.8l19.2 25.6 48-36-19.2-25.6zM576 64a64 64 0 1 0 64 64 64.06 64.06 0 0 0-64-64z'
    };

    // Market
    static MARKET: SvgDetails = {
        name: 'market', viewbox: '0 0 448 512',
        primaryPath: 'M252.8 288h-5a103 103 0 0 1-79.6 0h-5c-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6zM208 256a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm228-32h-20v64h20a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12zm0 128h-20v64h20a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12zm0-256h-20v64h20a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12z',
        secondaryPath: 'M416 48a48 48 0 0 0-48-48H48A48 48 0 0 0 0 48v416a48 48 0 0 0 48 48h320a48 48 0 0 0 48-48zm-208 80a64 64 0 1 1-64 64 64.06 64.06 0 0 1 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H118.4C106 384 96 375.4 96 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5a103 103 0 0 0 79.6 0h5c37.1 0 67.2 25.8 67.2 57.6z'
    };

    // Scheduler
    static SCHEDULER: SvgDetails = {
        name: 'scheduler', viewbox: '0 0 448 512',
        primaryPath: 'M448 112v80H0v-80a48 48 0 0 1 48-48h48v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h128v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h48a48 48 0 0 1 48 48z',
        secondaryPath: 'M0 192v272a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V192zm304-64h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16zm-192 0h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16z'
    };

    // Reports
    static REPORTS: SvgDetails = {
        name: 'reports', viewbox: '0 0 384 512',
        primaryPath: 'M377 105L279.1 7a24 24 0 0 0-17-7H256v112a16 16 0 0 0 16 16h112v-6.1a23.9 23.9 0 0 0-7-16.9zM160 210.66C90.91 224.8 43.42 298.14 73.47 375.5a111.26 111.26 0 0 0 63 63c77.39 30.08 150.73-17.41 164.87-86.5H160z',
        secondaryPath: 'M384 128H272a16 16 0 0 1-16-16V0H24A23.94 23.94 0 0 0 0 23.88V488a23.94 23.94 0 0 0 23.88 24H360a23.94 23.94 0 0 0 24-23.88V128zM136.47 438.5a111.26 111.26 0 0 1-63-63c-30-77.36 17.44-150.7 86.53-164.84V352h141.34c-14.14 69.09-87.48 116.58-164.87 86.5zM192 320V199.86A120.12 120.12 0 0 1 312.14 320z'
    };

    // Admin -> re-use for admin, matcher, my stuff
    static ADMIN: SvgDetails = {
        name: 'admin', viewbox: '0 0 512 512',
        primaryPath: 'M336 32H176a48 48 0 0 0-48 48v80h64V96h128v64h64V80a48 48 0 0 0-48-48zM176 288h-32a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm192 0h-32a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16z',
        secondaryPath: 'M384 368a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-16H192v16a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-16H0v96a32 32 0 0 0 32 32h448a32 32 0 0 0 32-32v-96H384zm118.63-153.37l-45.25-45.25a32 32 0 0 0-22.63-9.38H77.25a32 32 0 0 0-22.63 9.37L9.37 214.63A32 32 0 0 0 0 237.26V320h128v-16a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v16h128v-16a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v16h128v-82.75a32 32 0 0 0-9.37-22.62z',
        nonePath: 'M0 0h24v24H0V0z'
    };

    // Employees
    static EMPLOYEES: SvgDetails = {
        name: 'employees', viewbox: '0 0 576 512',
        primaryPath: 'M224 352h-8.2a103 103 0 0 1-79.6 0H128a64.09 64.09 0 0 0-60.9 44.2C63.9 406 72.3 416 82.7 416h186.6c10.4 0 18.8-9.9 15.6-19.8A64.09 64.09 0 0 0 224 352zM528 32H48A48 48 0 0 0 0 80v48h576V80a48 48 0 0 0-48-48zM176 320a64 64 0 1 0-64-64 64 64 0 0 0 64 64z',
        secondaryPath: 'M0 128v304a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48V128zm176 64a64 64 0 1 1-64 64 64 64 0 0 1 64-64zm93.3 224H82.7c-10.4 0-18.8-10-15.6-19.8A64.09 64.09 0 0 1 128 352h8.2a103 103 0 0 0 79.6 0h8.2a64.09 64.09 0 0 1 60.9 44.2c3.2 9.9-5.2 19.8-15.6 19.8zM512 344a8 8 0 0 1-8 8H360a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8zm0-64a8 8 0 0 1-8 8H360a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8zm0-64a8 8 0 0 1-8 8H360a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8z'
    };

    // LOGIN
    static LOGIN: SvgDetails = {
        name: 'login', viewbox: '0 0 640 512',
        primaryPath: 'M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm96 64a63.08 63.08 0 0 1 8.1-30.5c-4.8-.5-9.5-1.5-14.5-1.5h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h280.9a63.54 63.54 0 0 1-8.9-32z',
        secondaryPath: 'M608 288h-32v-80a80 80 0 0 0-160 0v80h-32a32 32 0 0 0-32 32v160a32 32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zM496 432a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm32-144h-64v-80a32 32 0 0 1 64 0z'
    };

    // Network
    static NETWORK: SvgDetails = {
        name: 'network', viewbox: '0 0 640 512',
        primaryPath: 'M224 192h192a32 32 0 0 0 32-32V32a32 32 0 0 0-32-32H224a32 32 0 0 0-32 32v128a32 32 0 0 0 32 32zm32-128h128v64H256zm320 256H416a32 32 0 0 0-32 32v128a32 32 0 0 0 32 32h160a32 32 0 0 0 32-32V352a32 32 0 0 0-32-32zm-32 128h-96v-64h96zM224 320H64a32 32 0 0 0-32 32v128a32 32 0 0 0 32 32h160a32 32 0 0 0 32-32V352a32 32 0 0 0-32-32zm-32 128H96v-64h96z',
        secondaryPath: 'M624 232H344v-40h-48v40H16a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h104v40h48v-40h304v40h48v-40h104a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16z'
    };

    // Notes
    static NOTES: SvgDetails = {
        name: 'notes', viewbox: '0 0 384 512',
        primaryPath: 'M256 64a64 64 0 0 0-128 0 64 64 0 0 0-64 64h256a64 64 0 0 0-64-64zm-64 24a24 24 0 1 1 24-24 23.94 23.94 0 0 1-24 24z',
        secondaryPath: 'M336 63h-80v1a64 64 0 0 1 64 64H64a64 64 0 0 1 64-64v-1H48a48 48 0 0 0-48 48v352a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V111a48 48 0 0 0-48-48z'
    };

    static STICKY: SvgDetails = {
        name: 'sticky', viewbox: '0 0 448 512',
        primaryPath: 'M320 480V368a16 16 0 0 1 16-16h112v6.1a23.9 23.9 0 0 1-7 16.9l-98 98a24 24 0 0 1-17 7z',
        secondaryPath: 'M448 352H336a16 16 0 0 0-16 16v112H24a23.94 23.94 0 0 1-24-23.88V56a23.94 23.94 0 0 1 23.88-24H424a23.94 23.94 0 0 1 24 23.88V352z'
    };

    // Settings
    static SETTINGS: SvgDetails = {
        name: 'settings', viewbox: '0 0 448 512',
        primaryPath: 'M224 184a23.94 23.94 0 0 0-24-24H88a23.94 23.94 0 0 0-24 24v16a23.94 23.94 0 0 0 24 24h112a23.94 23.94 0 0 0 24-24zm136 104H248a23.94 23.94 0 0 0-24 24v16a23.94 23.94 0 0 0 24 24h112a23.94 23.94 0 0 0 24-24v-16a23.94 23.94 0 0 0-24-24z',
        secondaryPath: 'M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zM200 224h-24v180a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12V224H88a23.94 23.94 0 0 1-24-24v-16a23.94 23.94 0 0 1 24-24h24v-52a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12v52h24a23.94 23.94 0 0 1 24 24v16a23.94 23.94 0 0 1-24 24zm184 104a23.94 23.94 0 0 1-24 24h-24v52a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-52h-24a23.94 23.94 0 0 1-24-24v-16a23.94 23.94 0 0 1 24-24h24V108a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12v180h24a23.94 23.94 0 0 1 24 24z'
    };

    // Balance
    static BALANCE: SvgDetails = {
        name: 'balance', viewbox: '0 0 640 512',
        primaryPath: 'M256 336c0-16.18 1.34-8.73-85-181.51-17.65-35.29-68.19-35.36-85.87 0C-2.06 328.75 0 320.33 0 336c0 44.18 57.31 80 128 80s128-35.82 128-80zM128 176l72 144H56zm512 160c0-16.18 1.34-8.73-85.05-181.51-17.65-35.29-68.19-35.36-85.87 0C381.94 328.75 384 320.33 384 336c0 44.18 57.31 80 128 80s128-35.82 128-80zm-200-16l72-144 72 144z',
        secondaryPath: 'M544 464v32a16 16 0 0 1-16 16H112a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h176V153.25A80.06 80.06 0 0 1 241.61 96H112a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h144.36a79.28 79.28 0 0 1 127.28 0H528a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H398.39A80.06 80.06 0 0 1 352 153.25V448h176a16 16 0 0 1 16 16z'
    };

    // Theme
    static THEME: SvgDetails = {
        name: 'theme', viewbox: '0 0 24 24',
        primaryPath: 'M6 4v16h11.6l-2.85-2.85c-.83.55-1.79.83-2.75.83-1.28 0-2.55-.49-3.53-1.46-1.95-1.95-1.95-5.11 0-7.05C9.45 8.49 10.72 8 12 8c1.28 0 2.55.49 3.53 1.46 1.71 1.71 1.92 4.34.64 6.28L18 17.58V9l-5-5H6z',
        secondaryPath: 'M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 15.58l-1.84-1.84c1.28-1.94 1.07-4.57-.64-6.28C14.55 8.49 13.28 8 12 8c-1.28 0-2.55.49-3.53 1.46-1.95 1.95-1.95 5.11 0 7.05.97.97 2.25 1.46 3.53 1.46.96 0 1.92-.28 2.75-.83L17.6 20H6V4h7l5 5v8.58zm-3.01-4.59c0 .8-.31 1.55-.88 2.11-.56.56-1.31.88-2.11.88s-1.55-.31-2.11-.88c-.56-.56-.88-1.31-.88-2.11s.31-1.55.88-2.11S11.2 10 12 10s1.55.31 2.11.88c.57.56.88 1.31.88 2.11z'
    };

    // Add Home
    static HOME: SvgDetails = {
        name: 'home', viewbox: '0 0 24 24',
        primaryPath: 'M6 4v16h11.6l-2.85-2.85c-.83.55-1.79.83-2.75.83-1.28 0-2.55-.49-3.53-1.46-1.95-1.95-1.95-5.11 0-7.05C9.45 8.49 10.72 8 12 8c1.28 0 2.55.49 3.53 1.46 1.71 1.71 1.92 4.34.64 6.28L18 17.58V9l-5-5H6z',
        secondaryPath: 'M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 15.58l-1.84-1.84c1.28-1.94 1.07-4.57-.64-6.28C14.55 8.49 13.28 8 12 8c-1.28 0-2.55.49-3.53 1.46-1.95 1.95-1.95 5.11 0 7.05.97.97 2.25 1.46 3.53 1.46.96 0 1.92-.28 2.75-.83L17.6 20H6V4h7l5 5v8.58zm-3.01-4.59c0 .8-.31 1.55-.88 2.11-.56.56-1.31.88-2.11.88s-1.55-.31-2.11-.88c-.56-.56-.88-1.31-.88-2.11s.31-1.55.88-2.11S11.2 10 12 10s1.55.31 2.11.88c.57.56.88 1.31.88 2.11z'
    };

    // map
    static MAP: SvgDetails = {
        name: 'map', viewbox: '0 0 576 512',
        primaryPath: 'M288 0a126 126 0 0 0-126 126c0 56.26 82.35 158.8 113.9 196a15.77 15.77 0 0 0 24.2 0C331.65 284.8 414 182.26 414 126A126 126 0 0 0 288 0zm0 168a42 42 0 1 1 42-42 42 42 0 0 1-42 42z',
        secondaryPath: 'M554.06 161.16L416 224v288l139.88-55.95A32 32 0 0 0 576 426.34V176a16 16 0 0 0-21.94-14.84zM20.12 216A32 32 0 0 0 0 245.66V496a16 16 0 0 0 21.94 14.86L160 448V214.92a302.84 302.84 0 0 1-21.25-46.42zM288 359.67a47.78 47.78 0 0 1-36.51-17C231.83 319.51 210.92 293.09 192 266v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72A47.8 47.8 0 0 1 288 359.67z'
    };


    // spaceship
    static SHIP: SvgDetails = {
        name: 'ship', viewbox: '0 0 24 24',
        primaryPath: 'M6 4v16h11.6l-2.85-2.85c-.83.55-1.79.83-2.75.83-1.28 0-2.55-.49-3.53-1.46-1.95-1.95-1.95-5.11 0-7.05C9.45 8.49 10.72 8 12 8c1.28 0 2.55.49 3.53 1.46 1.71 1.71 1.92 4.34.64 6.28L18 17.58V9l-5-5H6z',
        secondaryPath: 'M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 15.58l-1.84-1.84c1.28-1.94 1.07-4.57-.64-6.28C14.55 8.49 13.28 8 12 8c-1.28 0-2.55.49-3.53 1.46-1.95 1.95-1.95 5.11 0 7.05.97.97 2.25 1.46 3.53 1.46.96 0 1.92-.28 2.75-.83L17.6 20H6V4h7l5 5v8.58zm-3.01-4.59c0 .8-.31 1.55-.88 2.11-.56.56-1.31.88-2.11.88s-1.55-.31-2.11-.88c-.56-.56-.88-1.31-.88-2.11s.31-1.55.88-2.11S11.2 10 12 10s1.55.31 2.11.88c.57.56.88 1.31.88 2.11z'
    };

    // deathstar
    static DEATHSTAR: SvgDetails = {
        name: 'starwars', viewbox: '0 0 512 512',
        primaryPath: 'M256,320c-90.52344,0-174.80469-13.23047-246.27344-35.73438a246.11376,246.11376,0,0,0,6.98438,35.998C87.28906,340.53906,168.65625,352,256,352s168.71094-11.46094,239.28906-31.73633a246.11376,246.11376,0,0,0,6.98438-35.998C430.80469,306.76953,346.52344,320,256,320Zm-64-80a80,80,0,1,0-80-80A80.00021,80.00021,0,0,0,192,240Zm0-104a24,24,0,1,1-24,24A23.99993,23.99993,0,0,1,192,136Z',
        secondaryPath: 'M16.71094,320.26367C45.05859,426.01953,141.29688,504,256,504s210.94141-77.98047,239.28906-183.73633C424.71094,340.53906,343.34375,352,256,352S87.28906,340.53906,16.71094,320.26367ZM256,8C119.03125,8,8,119.0332,8,256a250.017,250.017,0,0,0,1.72656,28.26562C81.19531,306.76953,165.47656,320,256,320s174.80469-13.23047,246.27344-35.73438A250.017,250.017,0,0,0,504,256C504,119.0332,392.96875,8,256,8ZM192,240a80,80,0,1,1,80-80A80.00021,80.00021,0,0,1,192,240Z'
    };

    // night
    static NIGHT: SvgDetails = {
        name: 'night', viewbox: '0 0 512 512',
        primaryPath: 'M332.2 426.4c8.1-1.6 13.9 8 8.6 14.5a191.18 191.18 0 0 1-149 71.1C85.8 512 0 426 0 320c0-120 108.7-210.6 227-188.8 8.2 1.6 10.1 12.6 2.8 16.7a150.3 150.3 0 0 0-76.1 130.8c0 94 85.4 165.4 178.5 147.7z',
        secondaryPath: 'M320 32L304 0l-16 32-32 16 32 16 16 32 16-32 32-16zm138.7 149.3L432 128l-26.7 53.3L352 208l53.3 26.7L432 288l26.7-53.3L512 208z'
    };
    // sunburst
    static SUNBURST: SvgDetails = {
        name: 'sunburst', viewbox: '0 0 512 512',
        primaryPath:  'M502.42 240.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.41-94.8a17.31 17.31 0 0 0-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4a17.31 17.31 0 0 0 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.41-33.5 47.3 94.7a17.31 17.31 0 0 0 31 0l47.31-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3a17.33 17.33 0 0 0 .2-31.1zm-155.9 106c-49.91 49.9-131.11 49.9-181 0a128.13 128.13 0 0 1 0-181c49.9-49.9 131.1-49.9 181 0a128.13 128.13 0 0 1 0 181z',
        secondaryPath: 'M352 256a96 96 0 1 1-96-96 96.15 96.15 0 0 1 96 96z',
    };
    // vampire
    static VAMPIRE: SvgDetails = {
        name: 'vampire', viewbox: '0 0 512 512',
        primaryPath: 'M96 64h224a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16H96a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16zm208 105.05V96H112v72.12C45.61 202.85 0 271.88 0 352a207.15 207.15 0 0 0 53.93 139.7 63.44 63.44 0 0 0 47 20.3H315a64 64 0 0 0 47.57-20.85A207.15 207.15 0 0 0 416 356.67c1.71-79.95-44.8-151.49-112-187.62zm-32 215V416a16 16 0 0 1-16 16h-96a16 16 0 0 1-16-16v-31.92c-29-15.91-48-42.21-48-72.08 0-48.6 50.13-88 112-88s112 39.4 112 88c0 29.87-19 56.17-48 72.08z',
        secondaryPath: 'M208 224c-61.86 0-112 39.4-112 88 0 29.87 19 56.17 48 72.08V416a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-31.92c29-15.91 48-42.21 48-72.08 0-48.6-50.17-88-112-88zm-40 112a24 24 0 1 1 24-24 24 24 0 0 1-24 24zm80 0a24 24 0 1 1 24-24 24 24 0 0 1-24 24z'
    };
    // work
    static WORK: SvgDetails = {
        name: 'work', viewbox: '0 0 512 512',
        primaryPath: 'M464 128h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128z',
        secondaryPath: 'M320 288h192v144c0 25.6-22.4 48-48 48H48c-25.6 0-48-22.4-48-48V288h192v48a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16z'
    };
    // purple
    static PURPLE: SvgDetails = {
        name: 'purple', viewbox: '0 0 512 512',
        primaryPath: 'M464 128h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128z',
        secondaryPath: 'M320 288h192v144c0 25.6-22.4 48-48 48H48c-25.6 0-48-22.4-48-48V288h192v48a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16z'
    };

    // users
    static USERS: SvgDetails = {
        name: 'users', viewbox: '0 0 640 512',
        primaryPath: 'M192 256A112 112 0 1 0 80 144a111.94 111.94 0 0 0 112 112zm76.8 32h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 0 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 268.8 288z',
        secondaryPath: 'M480 256a96 96 0 1 0-96-96 96 96 0 0 0 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592a48 48 0 0 0 48-48 111.94 111.94 0 0 0-112-112z'
    };

    // droplet
    static DROPLET: SvgDetails = {
        name: 'droplet', viewbox: '0 0 352 512',
        primaryPath: 'M176 448A112.14 112.14 0 0 1 64 336a16 16 0 0 1 32 0 80.09 80.09 0 0 0 80 80 16 16 0 0 1 0 32z',
        secondaryPath: 'M205.22 22.09c-7.94-28.78-49.44-30.12-58.44 0C100 179.85 0 222.72 0 333.91 0 432.35 78.72 512 176 512s176-79.65 176-178.09c0-111.75-99.79-153.34-146.78-311.82zM176 448A112.14 112.14 0 0 1 64 336a16 16 0 0 1 32 0 80.09 80.09 0 0 0 80 80 16 16 0 0 1 0 32z'
    };

    static INFO: SvgDetails = {
        name: 'info', viewbox: '0 0 512 512',
        primaryPath: 'M256 202a42 42 0 1 0-42-42 42 42 0 0 0 42 42zm44 134h-12V236a12 12 0 0 0-12-12h-64a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h12v64h-12a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12v-24a12 12 0 0 0-12-12z',
        secondaryPath: 'M256 8C119 8 8 119.08 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 110a42 42 0 1 1-42 42 42 42 0 0 1 42-42zm56 254a12 12 0 0 1-12 12h-88a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h12v-64h-12a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h64a12 12 0 0 1 12 12v100h12a12 12 0 0 1 12 12z'
    };

    static COFFEE: SvgDetails = {
        name: 'coffee', viewbox: '0 0 640 512',
        primaryPath: 'M512.06 32h-392a23.94 23.94 0 0 0-24 24v232a96 96 0 0 0 96 96h192a96 96 0 0 0 96-96h32a128 128 0 0 0 0-256zm0 192h-32V96h32a64 64 0 0 1 0 128z',
        secondaryPath: 'M559.76 480H48.36c-47.6 0-61-64-36-64h583.3c25 0 11.8 64-35.9 64z'
    };

    static TREES: SvgDetails = {
        name: 'trees', viewbox: '0 0 640 512',
        primaryPath: 'M381.86 402.76a23.19 23.19 0 0 1-21 13.25H23.13a23.17 23.17 0 0 1-21-13.25 22.09 22.09 0 0 1 3.56-24.26L85.58 288H55a22.72 22.72 0 0 1-20.83-13.08 22.06 22.06 0 0 1 3.27-24L115.58 160H86.69a22.67 22.67 0 0 1-20.87-13.6 22.25 22.25 0 0 1 4.24-24.08L180.34 4.84c6-6.45 17.32-6.45 23.32 0l110.27 117.47a22.27 22.27 0 0 1 4.24 24.08A22.69 22.69 0 0 1 297.31 160h-28.89l78.13 91a22 22 0 0 1 3.28 24 22.73 22.73 0 0 1-20.78 13h-30.63l79.88 90.5a22.12 22.12 0 0 1 3.56 24.26z',
        secondaryPath: 'M224 440.46V416h-64v24.45l-30.29 48.4A16 16 0 0 0 144 512h96a16 16 0 0 0 14.31-23.16zm410.31-62L554.42 288h30.63a22.73 22.73 0 0 0 20.78-13 22 22 0 0 0-3.28-24l-78.13-91h28.89a22.69 22.69 0 0 0 20.86-13.61 22.27 22.27 0 0 0-4.24-24.08L459.66 4.84c-6-6.45-17.28-6.45-23.32 0L341.28 106.1c11.09 15.37 14 35.3 6.34 53a54.48 54.48 0 0 1-18.26 22.68l41.54 48.32a54.56 54.56 0 0 1-6.07 76.9q-2 1.68-4.1 3.18l41.62 47.15C411 367.11 415.69 371.46 416 384v56.45l-30.29 48.4A16 16 0 0 0 400 512h96a16 16 0 0 0 14.31-23.16L480 440.46V416h136.87a23.19 23.19 0 0 0 21-13.25 22.15 22.15 0 0 0-3.56-24.25z'
    };

    // test
    static TEST: SvgDetails = {
        name: 'test', viewbox: '0 0 480 512',
        primaryPath: 'M477.65 186.12L309.45 18.33a8 8 0 0 0-11.3 0l-34 33.9a8 8 0 0 0 0 11.29l11.2 11.1L33 316.53c-38.8 38.69-45.1 102-9.4 143.5a102.44 102.44 0 0 0 78 35.9h.4a102.75 102.75 0 0 0 72.9-30.09l246.3-245.71 11.2 11.1a8 8 0 0 0 11.3 0l34-33.89a7.92 7.92 0 0 0-.05-11.22zM141 431.84a54.65 54.65 0 0 1-38.95 16h-.36A54.09 54.09 0 0 1 60 428.76c-8.67-10.08-12.85-23.53-11.76-37.86a64.77 64.77 0 0 1 18.61-40.4l242.4-241.9 78 77.54z',
        secondaryPath: 'M318 256L138.61 435.44a55.46 55.46 0 0 1-78.39.06 55.46 55.46 0 0 1-.09-78.44L161 256z'
    };

    static CARS: SvgDetails = {
        name: 'cars', viewbox: '0 0 640 512',
        primaryPath: 'M604.58 315.56L600 304.2l-17.87-49.08a99.92 99.92 0 0 0-93.2-63.12H375.07a99.92 99.92 0 0 0-93.23 63.12L264 304.2l-4.55 11.36a57.39 57.39 0 0 0-35.42 53v28.69a57 57 0 0 0 21.52 44.46V480a32 32 0 0 0 32 32h22.07a32 32 0 0 0 32-32v-32h200.79v32a32 32 0 0 0 32 32h22.07a32 32 0 0 0 32-32v-38.3A57 57 0 0 0 640 397.24v-28.69a57.39 57.39 0 0 0-35.42-52.99zM335.11 284c6.53-16.92 22.36-28 40-28h113.82c17.6 0 33.42 11.1 40 28L541 320H323zM300 407.85c-14.4 0-24-9.57-24-23.93S285.6 360 300 360s36 21.53 36 35.89-21.6 11.96-36 11.96zm264 0c-14.4 0-36 2.39-36-12S549.6 360 564 360s24 9.57 24 23.92-9.6 23.93-24 23.93z',
        secondaryPath: 'M252.13 243.23A131.77 131.77 0 0 1 375.07 160h38.41a57.49 57.49 0 0 0-32.9-36.44L376 112.2l-17.84-49.08A99.92 99.92 0 0 0 264.93 0H151.07a99.92 99.92 0 0 0-93.23 63.12L40 112.2l-4.55 11.36A57.39 57.39 0 0 0 0 176.55v28.69a57 57 0 0 0 21.52 44.46V288a32 32 0 0 0 32 32h22.07a32 32 0 0 0 32-32v-32h139.88zM111.11 92c6.53-16.92 22.36-28 40-28h113.82c17.6 0 33.42 11.1 40 28L317 128H99zM76 215.85c-14.4 0-24-9.57-24-23.92S61.6 168 76 168s36 21.53 36 35.89-21.6 11.96-36 11.96z'
    };

    static CHART: SvgDetails = {
        name: 'chart', viewbox: '0 0 512 512',
        primaryPath: 'M275.2 96h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8zm-96 128h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8zm288-160h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8zm-96 96h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8z',
        secondaryPath: 'M512 400v32a16 16 0 0 1-16 16H32a32 32 0 0 1-32-32V80a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v304h432a16 16 0 0 1 16 16z'
    };


    static SVG_LIST: SvgDetails[] = [ SvgIconComponent.CARROT, SvgIconComponent.UP, SvgIconComponent.DOWN,
            SvgIconComponent.LEFT, SvgIconComponent.RIGHT,
            SvgIconComponent.SEARCH,
            SvgIconComponent.TEST,
            SvgIconComponent.COFFEE,
            SvgIconComponent.INFO,
            SvgIconComponent.TREES,
            SvgIconComponent.DROPLET,
            SvgIconComponent.ACCOUNT,
            SvgIconComponent.STICKY,
            SvgIconComponent.CHART,
            SvgIconComponent.TRACKER,
            SvgIconComponent.QUALIFY,
            SvgIconComponent.TOWERS,
            SvgIconComponent.EXPANSE,
            SvgIconComponent.TEXT,
            SvgIconComponent.AGENTS,
            SvgIconComponent.SUPPORT,
            SvgIconComponent.NOC,
            SvgIconComponent.MARKET,
            SvgIconComponent.SCHEDULER,
            SvgIconComponent.REPORTS,
            SvgIconComponent.ADMIN,
            SvgIconComponent.EMPLOYEES,
            SvgIconComponent.NETWORK,
            SvgIconComponent.SETTINGS,
            SvgIconComponent.THEME,
            SvgIconComponent.HOME,
            SvgIconComponent.SHIP,
            SvgIconComponent.DEATHSTAR,
            SvgIconComponent.VAMPIRE,
            SvgIconComponent.WORK,
            SvgIconComponent.SUNBURST,
            SvgIconComponent.BALANCE,
            SvgIconComponent.NIGHT,
            SvgIconComponent.LOGIN,
            SvgIconComponent.USERS,
            SvgIconComponent.CARS,
            SvgIconComponent.NOTES,
            SvgIconComponent.PURPLE,
            SvgIconComponent.MAP,
            SvgIconComponent.MOON
        ];

    @Input() set id( value: string )
    {
        for ( const entry of SvgIconComponent.SVG_LIST ) {
            if ( entry.name === value ) {
                this.name = entry.name;
                this.primaryPath = entry.primaryPath;
                this.secondaryPath = entry.secondaryPath;
                this.viewbox = entry.viewbox;
            }
        }
    }

    name = '';
    primaryPath = '';
    secondaryPath = '';
    viewbox = '';

    constructor()
    {
    }

    ngOnInit()
    {
    }
}

export interface SvgDetails
{
    name: string;
    primaryPath: string;
    secondaryPath: string;
    viewbox: string;
    nonePath?: string;
}
