
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthMgr } from '../../provider/AuthMgr';

import * as screenfull from 'screenfull';

import { VERSION as matVersion } from '@angular/material/core';
import { VERSION as angVersion } from '@angular/core';
import { Version as appVersion } from '../../provider/Version';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'ActionBar',
    templateUrl: './ActionBarComponent.html',
    styleUrls: ['./ActionBarComponent.scss']
})
export class ActionBarComponent implements OnInit
{
    backgroundImgStr;
    @Input() set bg( value: string ) {
        this.backgroundImgStr = value;
    }
    

    themes: ActionItem[] = [
        { name: 'work', icon: 'fish' },
    ];
    themeMap: Map<string, ActionItem> = new Map();

    showMenu = false;

    constructor( private auth: AuthMgr,
                 private dialog: MatDialog,
                 private router: Router,
                 public afAuth: AngularFireAuth )
    {
        // Setup the map
        this.themes.forEach( x => this.themeMap.set( x.name, x ));
    }

    ngOnInit(): void
    {
    }

    getUserName(): string {
        return '';
        // const result = this.auth.wispUserBSubject.getValue();
        // return result == null ? "" : result.displayName;
    }

    getUserImageURL(): string {
        return '';
        // const result = this.auth.wispUserBSubject.getValue();
        // return result == null ? "" : result.photoURL;
    }

    showSearchDialog()
    {
        // const searchTag: SearchTag = {  tables: [], searchType: "All", result: null };

        // const prefSize = DialogHelper.getDialogDimensions( 1000, 700 );
        // const ref = this.dialog.open( SearchDialogComponent,  { width: `${prefSize.width}px`, // height: `${prefSize.height}px`,
        //        data: searchTag, panelClass: 'BM' });
        // ref.afterClosed().subscribe( async pleaseSave => {

        //     if( !pleaseSave ) { console.log("cancel"); return; }

        //     const roots = this.router.url.split("/");
        //     if( roots[1] === `support` ) {
        //         this.router.navigate( ["/support/", searchTag.id_selected] );
        //     }
        //     else {
        //         this.router.navigate( ["/main/", searchTag.id_selected] );
        //     }
        // });
    }

    getAppVersion(): string
    {
        return appVersion.VERSION;
    }

    getAngularVersion(): string
    {
        return angVersion.full;
    }

    getAngularMaterialVersion(): string
    {
        return matVersion.full;
    }

    async toggleFullscreen()
    {
		if (screenfull.isEnabled) {
			screenfull.toggle();
		}
    }

    logout()
    {
        console.log('Bye!');
        this.auth.signOut();
    }
}

export interface ActionItem
{
    name: string;
    icon: string;
}
