

import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { PostDialogInfo } from '../LongPostDialog/PostMgr';

@Component({
    templateUrl: 'ShortPostDialogComponent.html',
    styleUrls: ['ShortPostDialogComponent.scss']
})
export class ShortPostDialogComponent
{
    internalPost: PostDialogInfo;

    constructor( public dialogRef: MatDialogRef<ShortPostDialogComponent>,
            @Inject( MAT_DIALOG_DATA ) public data: PostDialogInfo )
    {
        this.internalPost = data;
    }

    updateImage( event ) {
        const base64Img: string = event;
        this.internalPost.imageb64 = base64Img;        
    }
}
