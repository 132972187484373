

import { NgModule, Injectable } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
// import { AngularFireModule } from '@angular/fire';

import { FormsModule } from '@angular/forms';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';

import {
	HammerGestureConfig,
	HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';


// My service singletons

// Other other stuff
import { environment } from '../environments/environment';
import { AuthMgr } from './provider/AuthMgr';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { SharedModule } from './shared/shared.module'; ;
import { MatIconModule } from '@angular/material/icon';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { StorageMgr } from './provider/StorageMgr';

@Injectable()
export class HammerConfig extends HammerGestureConfig
{
	overrides = {
		swipe: { velocity: 0.4, threshold: 20 }
	} as any;
}

// Application wide providers
const APP_PROVIDERS = [
	{ provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    AuthMgr,
    StorageMgr,
    Title
];


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp( environment.firebaseConfig ),
    AngularFireDatabaseModule,
    AngularFirePerformanceModule,
    AngularFireFunctionsModule,
    FormsModule,
    MatIconModule,
    HttpClientModule,
    MaterialModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    AngularFireStorageModule,
    QuillModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [ APP_PROVIDERS ],
  bootstrap: [ AppComponent ],
  entryComponents: []
})
export class AppModule { }

