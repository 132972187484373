
import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import * as dateFormat from 'dateformat';
import { Post } from 'functions/src/Data/DataTypes';

@Component({
	selector: "post-card",
	templateUrl: './PostCardComponent.html',
    styleUrls: ['./PostCardComponent.scss'],
})
export class PostCardComponent
{
    internalPost: Post;
    timeStr: string = "";
    @Input() set post( value: Post )
    {
        this.internalPost = value;
        this.timeStr = dateFormat( value.lt_dt, "dddd, mmmm dS, yyyy, h:MM:ss TT");
    }

    constructor( private router: Router )
    {
    }

    goAuthor() {
        this.router.navigate( ["/user", this.internalPost.author_id  ] );
    }

    goBody() {
        this.router.navigate( ["/user", this.internalPost.author_id, this.internalPost.id ] );
    }

}


