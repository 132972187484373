
    
<div class="main-dialog">
    
    <div class="mid">
        <!-- <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="4 / 3"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"></image-cropper>

        <img [src]="croppedImage" />  -->
        
    </div>

    <div class="bot">
        <div class="ok-footer">
            <button [mat-dialog-close]="true" class="ok-button">EYG</button>
        </div>
    </div>
</div>
