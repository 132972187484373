
import { AngularFireStorage, AngularFireUploadTask } from "@angular/fire/storage";
import { take, first, finalize } from "rxjs/operators";
import { DBSchema, IDBPDatabase, openDB } from 'idb';
import { StorageItem, STORAGE_TYPE } from "functions/src/Data/DataTypes";
import { AngularFireDatabase } from "@angular/fire/database";
import { AuthMgr } from "./AuthMgr";
import { gzip } from "pako";
import { UploadMetadata } from "@angular/fire/storage/interfaces";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { Buffer } from 'buffer';

@Injectable({ providedIn: 'root' })
export class StorageMgr
{
    localStorage: LocalStorageMgr = new LocalStorageMgr();

    constructor( private storage: AngularFireStorage,
            private db: AngularFireDatabase,
            private auth: AuthMgr ) {}

    async load( id: string ): Promise<StorageItem>
    {
        // Check our local copy first
        let item: StorageItem = await this.localStorage.loadFromLocalDb( id );
        if( item != null ) {  console.log( `Local ${id} hit`); return item; }

        // From storage path
        const path = this.getStoragePath( id );
        const buf = await this.loadFromRemoteStorage( path );
        if( buf != null ) {
            console.log( `Remote file ${path} hit`);
            item = JSON.parse( buf.toString() );
            await this.localStorage.updateToLocalDb( item );
        }
        throw Error( `ID ${id} doesn't exist`);
    }

    createEmptyStorageItem(): StorageItem
    {
        const now = new Date().valueOf();
        const item: StorageItem = {
            id: this.db.createPushId(), type: STORAGE_TYPE.ANY, author_id: "",
            cr_dt: now, lt_dt: now, content_name: "", content: "",
        };
        return item;
    }

    async upload( item: StorageItem )
    {
        if( item.id == null || item.id === "" ) { item.id = this.db.createPushId(); }

        const promises: Promise<any>[] = [];

        // Save it to storage
        const itemStr = JSON.stringify( item );
        const path = this.getStoragePath( item.id );
        promises.push( this.saveStringToCloudStorage( path, itemStr, null ));

        // Save it to local
        promises.push( this.localStorage.updateToLocalDb( item ));

        // Save it to the database
        // promises.push( this.db.database.ref( path ).set( item ));
        
        await Promise.all( promises );
        return;
    }

    private getStoragePath( id: string ): string
    {
        return `storage_items/${id}`;
    }

    private async loadFromRemoteStorage( path: string ): Promise<Buffer>
    {
        const storeRef = this.storage.ref( path );
        try
        {
            const result = await storeRef.getDownloadURL().pipe( take( 1 )).pipe( first()).toPromise().then( theURL => {
                return this.loadAsPromise( theURL );
            });
            return result;
        }
        catch( exception ) {
            console.log( `Failed to load ${path} from storage`);
        }
        return null;
    }

    // works for /assets and fire storage things
    private async loadAsPromise( myURL: string ): Promise<Buffer>
    {
        console.log("using XMLHttpRequest - replace with fetch");

        const myPromise = new Promise<Buffer>((resolve, reject) => 
        {    
            const xhr = new XMLHttpRequest();
            xhr.responseType = "arraybuffer";    
            xhr.open('GET', myURL);
            xhr.onload = function( event )
            {
                if (xhr.status >= 200 && xhr.status < 300) {
                    const buffer = Buffer.from( xhr.response );
                    resolve( buffer );
                } 
                else {
                    reject(xhr.statusText);
                }
            }; 
            xhr.onerror = () => reject(xhr.statusText);
            xhr.send();
        });
        return myPromise;
    }

    // Make the Call
    // async httpsFunctionCall( function_name: string, bodyObj: any ): Promise<string>
    // {
    //     // Setup the call
    //     const url: string = `https://us-central1-wispltd-f366e.cloudfunctions.net/${function_name}`;
    //     const request = JSON.stringify( bodyObj );
    //     const theOptions: RequestInit = { method: "post", body:  request , cache: "no-cache" };

    //     // Make the call and return the response
    //     const response = await fetch( url, theOptions );
    //     return response.text();        
    // }

    // async rawUrlCall( url: string, bodyObj: any ): Promise<string>
    // {
    //     const request = JSON.stringify( bodyObj );
    //     console.log( request );
    //     // debugger;
    //     const theOptions: RequestInit = { method: "post", 
    //         headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //         },
    //         body:  request , 
    //         cache: "no-cache" };

    //     // Make the call and return the response
    //     const response = await fetch( url, theOptions );
    //     return response.text();        
    // }

    private saveStringToCloudStorage( path: string, dataString: string, listener?: Subject<number> )
    {
        return this.saveBufferToCloudStorage( path, Buffer.from( dataString ), listener );
    }
    

    // Save the image to the cloud
    private saveBufferToCloudStorage( path: string, data: Buffer, listener?: Subject<number> ): Promise<string>
    {
        // console.log( "original size: "+data.length );
        return this.gzip( data ).then( value => { 

            const storeRef = this.storage.ref( path );
            const updMetadata: UploadMetadata = {
                contentEncoding: "gzip", contentType: "application/octet-stream"
            };

            const task: AngularFireUploadTask = storeRef.put( value, updMetadata );
            task.percentageChanges().subscribe( percent => {
                if( listener ) { listener.next( percent ); }
            });

            return  task.snapshotChanges().pipe( finalize( () => {} )).toPromise();
        }).then( () => {
            return "uploaded";
        });
    }

    private async gzip( input: Buffer ): Promise<Buffer>
    {
        const zzz: Uint8Array = new Uint8Array( input );
        const compressed: Uint8Array = gzip( zzz );
        return Buffer.from( compressed );
    }
}

export class LocalStorageMgr
{
    private isOpen = false;
    private db: IDBPDatabase<MLStorage>;

    constructor() {}

    private async open()
    {
        if( this.isOpen ) { return; }

        this.db = await openDB<MLStorage>('MLStorage', 2, {
            upgrade(db) {
                try { db.createObjectStore( "storageItems" ); }
                catch( ignore ) {}
            },
        });
        this.isOpen = true;
    }

    private close()
    {
        this.db.close();
        this.isOpen = false;
    }

    async loadFromLocalDb( id: string ): Promise<StorageItem>
    {
        try
        {
            await this.open();
            const item: StorageItem = await this.db.get( "storageItems", id );
            return item;
        }
        catch( exception ) { 
            console.log(`Failed to loadFromLocalDB ${id}`);
        }
        return null;
    }


    async updateToLocalDb( item: StorageItem )
    {
        if( !this.isOpen ) { await this.open(); }
        await this.db.put( "storageItems", item, ""+item.id );
    }
}

interface MLStorage extends DBSchema
{
    storageItems: { key: string; value: StorageItem; indexes: { 'id': string, 'lt_dt': number }; };
}
