

<div class="profile-pic">
    <img [src]="profileSrc" *ngIf="profileSrc !== ''">
    <!-- {{ internalProfile?.profile_storage_id }}  -->
</div>

<div class="profile-name">{{ internalProfile?.visible_name }}</div>

<div class="profile-id">@{{ internalProfile?.id }}</div>

<text-display class="profile-brief" [text]="internalProfile?.about_me"></text-display>
