
import { TinyColor } from '@ctrl/tinycolor';
import { NightTheme, PurpleTheme, SunburstTheme, ThemeSettings, VampireTheme, DropletTheme,
        CoffeeTheme, TreesTheme } from './ThemeSettings';


export class ThemeWizard
{
    static VAR_THEME = 'theme';

    theme_names: string[] = [];

    private themeMap: Map<string, ThemeSettings> = new Map();

    themeNow = '';

    constructor()
    {
        const themes: ThemeSettings[] = [
            new PurpleTheme(),
            new VampireTheme(),
            new DropletTheme(),
            new NightTheme(),
            new SunburstTheme(),
            new CoffeeTheme(),
            new TreesTheme()
        ];
        themes.forEach( x => this.themeMap.set( x.name, x ));
        themes.forEach( x => this.theme_names.push( x.name ));
    }

    capitalizeIt( input: string ): string
    {
        return input.charAt(0).toUpperCase() + input.slice(1);
    }

    init(): ThemeSettings
    {
        // try to get the theme
        let themeData = localStorage.getItem( ThemeWizard.VAR_THEME );

        // Make sure it's a valid theme - else throw it out
        try {
            if ( themeData != null ) {
                const xx: ThemeSettings = JSON.parse( themeData );
            }
        }
        catch ( ignore ) {
            console.log('Failed');
            console.log( ignore );
            themeData = null;
        }

        // if it doesn't exist, set it to the first one
        if ( themeData == null ) {
            themeData = JSON.stringify( new DropletTheme() );
            localStorage.setItem( ThemeWizard.VAR_THEME, themeData );
        }

        // If we have it but it doesn't exist - make sure it's destroyed
        let theme: ThemeSettings = JSON.parse( themeData );
        if ( ! this.themeMap.has( theme.name ) ) {
            themeData = JSON.stringify( new DropletTheme() );
            localStorage.setItem( ThemeWizard.VAR_THEME, themeData );
            theme = JSON.parse( themeData );
        }

        // Set it
        const bodyClass = document.body.classList;
        bodyClass.add( theme.name );

        return theme;
    }

    setTheme( name: string )
    {
        const current: ThemeSettings = this.init();
        const theme: ThemeSettings = this.themeMap.get( name );
        const bodyClass = document.body.classList;
        if ( theme == null ) {
            return;
        }
        bodyClass.replace( current.name, theme.name );

        this.setBaseColor( '1', theme['hex-1'] );
        this.setBaseColor( 'a', theme['hex-a'] );

        for ( const key of Object.keys( theme )) {
            document.documentElement.style.setProperty( `--${key}`, theme[key] );
        }
        localStorage.setItem( ThemeWizard.VAR_THEME, JSON.stringify( theme ));
        this.themeNow = theme.name;
    }


    // Helpers
    private setBaseColor( letter: string, baseColor: string )
    {
        const colors = this.computeColors( baseColor );
        for ( const color of colors ) {
            const key = `--t-${letter}-${color.name}`;
            const value = color.hex;
            document.documentElement.style.setProperty(key, value);
        }
    }

    private computeColors(hex: string): Color[]
    {
        return [
            this.getColorObject( new TinyColor( hex ).lighten( 52 ), '50'),
            this.getColorObject( new TinyColor( hex ).lighten(37), '100'),
            this.getColorObject( new TinyColor( hex ).lighten(26), '200'),
            this.getColorObject( new TinyColor( hex ).lighten(12), '300'),
            this.getColorObject( new TinyColor( hex ).lighten(6), '400'),
            this.getColorObject( new TinyColor( hex ), '500'),
            this.getColorObject( new TinyColor( hex ).darken(6), '600'),
            this.getColorObject( new TinyColor( hex ).darken(12), '700'),
            this.getColorObject( new TinyColor( hex ).darken(18), '800'),
            this.getColorObject( new TinyColor( hex ).darken(24), '900'),
            this.getColorObject( new TinyColor( hex ).lighten(50).saturate(30), 'A100'),
            this.getColorObject( new TinyColor( hex ).lighten(30).saturate(30), 'A200'),
            this.getColorObject( new TinyColor( hex ).lighten(10).saturate(15), 'A400'),
            this.getColorObject( new TinyColor( hex ).lighten(5).saturate(5), 'A700')
        ];
    }

    private getColorObject( color: TinyColor, colorName: string ): Color
    {
        // const color = new TinyColor( value );
        return {
            name: colorName,
            hex: color.toHexString(),
            darkContrast: color.isLight()
        };
    }
}

export interface Color
{
    name: string;
    hex: string;
    darkContrast: boolean;
}
