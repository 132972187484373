<div class="actionBar">

    <ng-container *ngIf="!(afAuth.authState | async)">

        <button mat-icon-button style="margin: 0px 20px 0px 20px;" matTooltip="Menu"
            [matMenuTriggerFor]="menu">
        HI
            <!-- <ngx-avatar  size="40" src="{{ getUserImageURL() }}" name="{{ getUserName() }}" ></ngx-avatar> -->
        </button>

        <mat-menu #menu="matMenu" class="menu-button">
            <button mat-menu-item>
                <i class="menu-i mdi mdi-angular"></i>
                <span>Angular: {{ getAngularVersion() }}</span>
            </button>
            <button mat-menu-item>
                <i class="menu-i mdi mdi-material-ui"></i>
                <span>Material: {{ getAngularMaterialVersion() }}</span>
            </button>
            <button mat-menu-item>
                <i class="menu-i mdi mdi-application-settings"></i>
                <span>WispLTD: {{ getAppVersion() }}</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="toggleFullscreen()">
                <i class="menu-i mdi mdi-overscan"></i>
                <span>Toggle Fullscreen</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="logout()">
                <i class="menu-i mdi mdi-logout"></i>
                <span>Logout</span>
            </button>

        </mat-menu> 
    </ng-container>

    <ng-container *ngIf="afAuth.authState | async as user">

        <button mat-icon-button
                (click)="showSearchDialog()"
                matTooltip="Contact Search">
            <i class="mdi mdi-account-search"></i>
        </button>

        <button mat-icon-button style="margin: 0px 20px 0px 20px;" matTooltip="Menu"
            [matMenuTriggerFor]="menu">
            HI
            <!-- <ngx-avatar  size="40" src="{{ getUserImageURL() }}" name="{{ getUserName() }}" ></ngx-avatar> -->
        </button>

        <mat-menu #menu="matMenu" class="menu-button">
            <button mat-menu-item>
                <i class="menu-i mdi mdi-angular"></i>
                <span>Angular: {{ getAngularVersion() }}</span>
            </button>
            <button mat-menu-item>
                <i class="menu-i mdi mdi-material-ui"></i>
                <span>Material: {{ getAngularMaterialVersion() }}</span>
            </button>
            <button mat-menu-item>
                <i class="menu-i mdi mdi-application-settings"></i>
                <span>WispLTD: {{ getAppVersion() }}</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="toggleFullscreen()">
                <i class="menu-i mdi mdi-overscan"></i>
                <span>Toggle Fullscreen</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="logout()">
                <i class="menu-i mdi mdi-logout"></i>
                <span>Logout</span>
            </button>

        </mat-menu> 

    </ng-container>
    

</div>
