import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'dnd-image',
  templateUrl: './DnDImageComponent.html',
  styleUrls: ['./DnDImageComponent.scss']
})
export class DnDImageComponent 
{
    imageStr = "";
    @Input() set img( value: string ) {
        this.imageStr = value;
    }

    @Output() imgChange = new EventEmitter<string>();

    @HostListener('dragover', ['$event']) onDragOver( evt ) {
        evt.preventDefault();
        evt.stopPropagation();
        // console.log( 'Drag over' );
        this.fileOver = true;
        this.flip = true;
    }
    @HostListener('dragleave', ['$event']) onDragLeave( evt ) {
        evt.preventDefault();
        evt.stopPropagation();
        // console.log( 'dragLeave' );
        this.flip = false;
    }
    @HostListener('drop', ['$event']) public onDrop( evt ) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        this.flip = false;
        // console.log( 'Drop' );
        const theFiles = evt.dataTransfer.files;
        // console.log( theFiles );        

        const file = theFiles[0];
        // console.log(file);
        const reader = new FileReader();
        // reader.readAsDataURL(file);
        reader.onloadend = () => {
            // logs data:<type>;base64,wL2dvYWwgbW9yZ...
            // console.log(reader.result);
            this.imageStr = <any>reader.result;
            this.imgChange.emit( this.imageStr );
        };
        reader.readAsDataURL(file);
    }

    @HostBinding('class.fileover') fileOver: boolean;

    flip = false;

    constructor() { }
}
